import { Axios } from 'api/axios';
import axios from 'axios';
import { toastError } from 'components/UI/toast';
import { store } from 'index';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { uploadProgress } from 'redux/actions/AssetsAction';
import { getSimplifiedError } from 'utils/error';
import {
  CREATE_ASSET_ERROR,
  CREATE_ASSET_REQUEST,
  CREATE_ASSET_SUCCESS,
  DOWNLOAD_ASSET_ERROR,
  DOWNLOAD_ASSET_REQUEST,
  DOWNLOAD_ASSET_SUCCESS,
  GET_ASSET_ERROR,
  GET_ASSET_REQUEST,
  GET_ASSET_SUCCESS,
  RESET_BLOCK_ASSET,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
} from '../reducers/AssetsReducer';

async function getAsset(payload) {
  return await Axios.get(`/assets/${payload}`);
}
function* handleGetAsset({ payload }) {
  try {
    const response = yield call(getAsset, payload);
    if (response) {
      yield put({
        type: GET_ASSET_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      // toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function downloadAsset(payload) {
  return await Axios.get(`/assets/${payload}`);
}
function* handleDownloadAsset({ payload }) {
  try {
    const response = yield call(downloadAsset, payload);
    if (response) {
      yield put({
        type: DOWNLOAD_ASSET_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      // toastError(getSimplifiedError(error));
    }
    yield put({
      type: DOWNLOAD_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}
async function createAsset(payload) {
  return await Axios.post('/assets', payload);
}

function* handleCreateAsset({ payload, scan }) {
  try {
    const response = yield call(createAsset, payload, scan);
    if (response) {
      yield put({
        type: CREATE_ASSET_SUCCESS,
        data: response.data,
      });
      // toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_ASSET,
        blockType: 'createAsset',
      });
      // yield put({
      //   type: GET_ASSET_REQUEST,
      // });
    }
  } catch (error) {
    if (error) {
      // toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

function* uploadFiles({
  payload: { acceptedFiles: payload, uploadedFilesMaxIndex, trackProgress, extraCall },
}) {
  const assetData = [];
  let index = uploadedFilesMaxIndex;
  try {
    for (const file of payload) {
      const addNewFile = (file) => {
        const payload = {
          fileName: file.name,
          fileMime: file.type,
        };

        return Axios.post('/files/url/upload', payload);
      };

      const signUrl = ({ signedUrl, file }) => {
        return axios.put(signedUrl, file, {
          headers: { 'Content-Type': file?.type },
          onUploadProgress: trackProgress
            ? (progressEvent) => {
                const loadedKB = Math.round(progressEvent.loaded / 1024);
                const progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total,
                );
                store.dispatch(
                  uploadProgress({
                    progress: {
                      [index]: {
                        data: progress,
                        loadedBytes: loadedKB,
                      },
                    },
                  }),
                );
              }
            : undefined,
        });
      };

      const { data } = yield call(addNewFile, file);

      const { key, originalFilename, signedUrl, url } = data;

      yield call(signUrl, { signedUrl, file });

      const fileType = file?.type.split('/')[1];

      const assetPayload = {
        key: key,
        name: file?.name,
        type: fileType,
      };

      const getAsset = yield call(Axios.post, '/assets', assetPayload);

      const {
        data: { asset },
      } = getAsset;

      if (trackProgress) {
        if (extraCall) yield call(extraCall, { asset: asset.code });

        yield put(
          uploadProgress({
            progress: {
              [index]: {
                data: 100,
                loading: false,
                assetCode: asset.code,
              },
            },
          }),
        );
      }

      assetData.push({
        assetCode: asset.code,
        key,
        originalFilename,
        signedUrl,
        url,
        fileType: file?.type,
        fileName: file?.name,
      });

      if (index) index++;
    }

    yield put({
      type: UPLOAD_FILE_SUCCESS,
      data: assetData,
    });

    yield delay(300);

    yield put({
      type: RESET_BLOCK_ASSET,
      blockType: 'uploadFiles',
    });
  } catch (error) {
    if (error) {
      if (trackProgress) {
        yield put(
          uploadProgress({
            progress: {
              [index]: {
                status: 'failed',
                loading: false,
              },
            },
          }),
        );
      } else {
        toastError(getSimplifiedError(error));
      }
      yield put({
        type: UPLOAD_FILE_ERROR,
        error: getSimplifiedError(error),
      });
    }
  }
}

export default all([
  takeLatest(GET_ASSET_REQUEST, handleGetAsset),
  takeLatest(DOWNLOAD_ASSET_REQUEST, handleDownloadAsset),
  takeLatest(CREATE_ASSET_REQUEST, handleCreateAsset),
  takeLatest(UPLOAD_FILE_REQUEST, uploadFiles),
]);
