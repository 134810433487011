import { CloseOutlined } from '@ant-design/icons';
import {
  BujetiFullLogo,
  KebabIcon,
  LogoutIcon,
  NavMenuIcon,
  OrganizationIcon,
  PlusIcon,
  BellIcon1,
} from 'assets/icons';

import { Progress } from 'antd';
import classNames from 'classnames';
import ImgCard, { CompanyListItem } from 'components/UI/ImgCard';
import CustomPopover from 'components/UI/Popover';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { logout } from 'redux/actions/AuthAction';
import {
  getCompanies,
  switchCompany,
  getCompanyStats,
} from 'redux/actions/CompaniesAction';
import { getNotification } from 'redux/actions/NotificationsAction';
import { RESET_STATE } from 'redux/reducers/AuthReducer';
import { allPermissions, returnPermissions } from 'utils/AllowedTo';
import { calculateProgressAndDaysLeft, toTitle } from 'utils/helper';
import { NavLinks, extraLinks } from 'utils/linkList';
import { filterSubLinks } from 'utils/utility';
import { RenderMenu } from './RenderMenu';
import './styles.scss';
import { toggleNotificationAction } from 'redux/actions/ToggleAction';

const Sidebar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const [page, setPage] = useState(1);

  const { user = {} } = useSelector(({ auth }) => auth);

  const {
    getStatistics: { data: statistics, loading },
    getCompanies: { data: resp = {}, ...rest },
    switchCompany: { loading: switching },
  } = useSelector(({ companies }) => companies);

  const { companies = [] } = resp;

  const {
    getBeneficiaryBank: { data: bankData = [] },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const [openNotification, setOpenNotification] = useState(false);
  const [userName, setUserName] = useState('');
  const [lastName, setLastName] = useState(null);
  const { permissions, isAdmin, isPremium, userStatus, onboardingStatus } =
    allPermissions();
  const [isLogoutPopover, setIsLogoutPopover] = useState(false);

  const canViewDash = returnPermissions({
    permissions,
    scopes: ['org-*', 'dash-view', 'dash-*'],
  });

  useEffect(() => {
    if (user.hasOwnProperty('data')) {
      setUserName(user?.data?.user.firstName || 'there');
      setLastName(user?.data?.user.lastName);
      dispatch(getCompanies());
    }
    if (!statistics?.pendingAmountsAndCounts)
      dispatch(getCompanyStats({ id: user?.data?.user?.company?.code }));
  }, [user]);

  //notifications redux selector
  const {
    getNotification: { data: notificationData },
  } = useSelector(({ notifications }) => notifications);

  const locationArray = location.pathname.split('/');

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSwitch = (code) => {
    const activeCompany = user?.data?.user?.company?.code;
    if (code === activeCompany) return;
    setIsLogoutPopover(false);
    dispatch(switchCompany({ code }));
  };

  const onLogout = () => {
    dispatch(logout());
    history.push('/login');
    dispatch({ type: RESET_STATE });
  };

  const AdditionnalActions = () => {
    const onNavigateHandler = (screenType) => () => {
      history.push(screenType);
    };

    return (
      <div className="actions-dialog user-icon-dropdown user__menu">
        <div className="inner-dialog-holder">
          {companies.map(({ name, code, paymentPlanName, members }, index) => (
            <div
              className={`user__menu-name company ${
                index === companies.length - 1 ? 'last-item' : 'mb-2'
              }`}
              key={index}
            >
              <CompanyListItem
                avatar={<OrganizationIcon />}
                label={name}
                subLabel={`${toTitle(paymentPlanName)} Plan - ${members} member${
                  members > 1 ? 's' : ''
                }`}
                value={code}
                onClick={handleSwitch}
                isActive={code === user?.data?.user?.company?.code}
              />
            </div>
          ))}
        </div>

        {isAdmin && isPremium && (
          <>
            <div
              onClick={onNavigateHandler(
                '/new-subsidiary?company=' + user?.data?.user.company.code,
              )}
              className="actionLink user__menu-earn w-100"
            >
              <PlusIcon className="me-1" stroke="#A9A29D" />
              <div className="d-flex align-items-center justify-content-between w-100">
                <span>Add a subsidiary</span>
              </div>
            </div>

            <div
              onClick={onNavigateHandler('/new-company')}
              className="actionLink user__menu-earn w-100"
              style={{ borderBottom: '1px solid #E7E5E4' }}
            >
              <PlusIcon className="me-1" stroke="#A9A29D" />
              <div className="d-flex align-items-center justify-content-between w-100">
                <span>Add a new company</span>
              </div>
            </div>
          </>
        )}

        <div onClick={onLogout} className="actionLink">
          <LogoutIcon className="me-2" stroke="#A9A29D" /> Log out
        </div>
      </div>
    );
  };

  const UserPlanDisplay = ({ expiryDate }) => {
    const { push } = useHistory();
    const { daysLeft, percentageRemaining } = calculateProgressAndDaysLeft(expiryDate);

    return (
      <section className="plan-holder">
        <div className="d-flex align-items-center">
          <span className="plan">Free trial</span>
          <span className="duration">{daysLeft > 0 ? daysLeft : 0} days left</span>
        </div>

        <Progress
          percent={percentageRemaining}
          showInfo={false}
          strokeColor="#12B76A"
          trailColor="#E7E5E4"
        />

        <div className="mt-2">
          <button className="upgrade w-100" onClick={() => push('/settings/plans')}>
            {' '}
            Upgrade{' '}
          </button>
        </div>
      </section>
    );
  };

  const pendingVerfication =
    (userStatus !== 'active' && !isAdmin) ||
    ['pending', 'rejected'].includes(onboardingStatus);

  const handleClick = (e) => {
    if (pendingVerfication) {
      e.preventDefault();
      if (['pending'].includes(onboardingStatus))
        toastError('Kindly complete your business onboarding to access this feature');
      if (['rejected'].includes(onboardingStatus))
        toastError('Your company account verification was rejected');
    }
  };

  const pendingOnbording = ['pending', 'rejected'].includes(onboardingStatus);

  const handleSupport = (e) => {
    e.preventDefault();
    window.open('https://blog.bujeti.com/category/help-center/', '_blank');
  };

  const handleNotification = (e) => {
    e.preventDefault();
    dispatch(toggleNotificationAction());
  };

  const extraLinkActions = {
    support: handleSupport,
    notification: handleNotification,
  };
  const stats = {
    pendingRequests: Object.values(statistics?.pendingAmountsAndCounts || {}).reduce(
      (total, currentValue) => total + Number(currentValue?.totalCount ?? 0),
      0,
    ),
  };
  const links = NavLinks(pendingOnbording, handleClick);
  const extraLinksItems = extraLinks(extraLinkActions);

  const homeLink = () => {
    if (
      !['pending', 'rejected'].includes(onboardingStatus) &&
      !canViewDash &&
      bankData[0]?.bankCode
    )
      return '/expenses/budgets';
    return '/';
  };

  const handleBell = () => {
    setOpenNotification(!openNotification);
  };

  // //notification api call
  // useEffect(() => {
  //   dispatch(getNotification({ page }));
  // }, []);

  useEffect(() => {
    if (notificationData) {
      if (notificationData?.meta?.hasMore) {
        setPage(page + 1);
      }
    }
  }, [notificationData]);

  const fetchMore = () => {
    dispatch(getNotification({ page }));
  };

  const hasUnseenNotification = notificationData?.notifications?.some(
    (notification) => !notification.seen,
  );

  //////////
  const fileterd = filterSubLinks(links, locationArray[1]) ?? [];
  const [openDropdowns, setOpenDropdowns] = useState(fileterd);

  const toggleDropdown = (index) => {
    setOpenDropdowns((prevOpenDropdowns) => {
      const isOpen = prevOpenDropdowns.includes(index);
      let newOpenDropdowns;
      if (isOpen) {
        newOpenDropdowns = prevOpenDropdowns.filter((i) => i !== index);
      } else {
        newOpenDropdowns = [index];
      }
      localStorage.setItem('isDropdownOpen', JSON.stringify(newOpenDropdowns));
      return newOpenDropdowns;
    });
  };

  return (
    <header className={`hide-scrollbar header-section ${switching ? 'blurred' : ''}`}>
      <div className="desktop-menu-wrap">
        <div className="d-flex align-items-center pt-4 mt-2 px-4">
          <Link to={homeLink} className="logo-link">
            <BujetiFullLogo className="w-fit object-scale-down" />
          </Link>

          <span className="ms-auto icon-animate">
            <BellIcon1 />
          </span>
        </div>

        <div
          className="d-flex h-100 justify-content-center"
          style={{ transitionDuration: '300ms' }}
        >
          <div className="desktop-menu">
            <div className="desktop-menu-list">
              <RenderMenu
                fileterd={fileterd}
                isSubLink={false}
                lists={links}
                stats={stats}
                locationArray={locationArray}
                openDropdowns={openDropdowns}
                toggleDropdown={toggleDropdown}
                permissions={permissions}
              />
            </div>

            <div className="mt-auto w-100 nav-item-wrap">
              {user?.data?.user.company?.paymentPlan?.freeTrial && (
                <UserPlanDisplay
                  expiryDate={user?.data?.user.company?.paymentPlan.expiryDate}
                />
              )}
              <div className=" pt-2">
                <RenderMenu
                  isSubLink={false}
                  locationArray={locationArray}
                  lists={extraLinksItems}
                  permissions={permissions}
                />
              </div>
            </div>
            <div className="logout-section mt-4">
              <div className="user__menu-name d-flex">
                <ImgCard
                  customGap="c-gap-10"
                  customProperties="img__card-initials_properties nav-img__card"
                  initials={<>{userName && userName.charAt(0)}</>}
                  label={<span style={{ fontSize: '0.875rem' }}> {userName} </span>}
                  value={
                    <span style={{ fontSize: '0.75rem' }}>
                      {' '}
                      {user?.data?.user?.company?.name?.split(' ')[0] || 'N/A'}{' '}
                    </span>
                  }
                />
                <div className="role-pill"> {user?.data?.user?.role || 'N/A'} </div>
              </div>

              <div className="cursor">
                <CustomPopover
                  placement="top-start"
                  customClass="multi-entity"
                  clickOutside={() => setIsLogoutPopover(!isLogoutPopover)}
                  content={<AdditionnalActions />}
                  showPopover={isLogoutPopover}
                >
                  <div
                    className="cursor"
                    style={{ transform: 'rotate(90deg)' }}
                    onClick={() => setIsLogoutPopover(!isLogoutPopover)}
                  >
                    <KebabIcon className="me-2" stroke="#79716B" />
                  </div>
                </CustomPopover>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <nav className="mobile-menu-nav">
        <div
          className={classNames('menu-nav-section space-x-4')}
          style={{ zIndex: isOpen ? '0' : '50' }}
        >
          <Link to={homeLink} className="align-items-start gap-2 onDesktop">
            <BujetiFullLogo className="w-32 object-scale-down" />
          </Link>
          <div
            style={{ zIndex: isOpen ? '0' : '50', paddingRight: '16px' }}
            onClick={handleToggle}
            className="cursor"
          >
            <NavMenuIcon className="text-gray-700" />
          </div>
        </div>
      </nav>

      {/* transition */}
      <div
        className={classNames(
          isOpen ? 'd-block' : 'd-none',
          'mobile-menu-wrap transform d-md-none',
        )}
        onClick={handleToggle}
      ></div>
      <div
        className={classNames(
          isOpen ? 'translateX-0' : 'translateX-100',
          'mobile-menu-content d-md-none',
        )}
      >
        <div className="logo-wrap">
          <Link to={homeLink} className="d-flex w-100 align-items-start">
            <BujetiFullLogo className="w-fit object-scale-down" />
          </Link>
          <div onClick={handleToggle} className="cursor">
            <CloseOutlined className="h-5 w-5 text-primary-700" />
          </div>
        </div>
        <div
          className="d-flex h-100 w-100 col algin-items-center justify-content-center"
          style={{ padding: '0 16px', flex: '1 1 0%' }}
        >
          <div className="h-100 w-100" style={{ maxWidth: '448px' }}>
            <div className="d-flex h-100 justify-content-center">
              <div className="mobile-menu">
                <div className={classNames('mobile-menu-list')}>
                  <RenderMenu
                    fileterd={fileterd}
                    isSubLink={false}
                    lists={links}
                    locationArray={locationArray}
                    openDropdowns={openDropdowns}
                    toggleDropdown={toggleDropdown}
                    permissions={permissions}
                  />
                </div>
                <div className="mt-auto w-100 nav-item-wrap bdr-t1 pt-2">
                  <RenderMenu
                    isSubLink={false}
                    locationArray={locationArray}
                    lists={extraLinksItems}
                    permissions={permissions}
                  />
                </div>

                <div className="logout-section mt-4">
                  <div className="user__menu-name">
                    <ImgCard
                      customProperties="img__card-initials_properties nav-img__card"
                      initials={<>{userName && userName.charAt(0)}</>}
                      label={userName}
                      value={user?.data?.user?.role || 'N/A'}
                    />
                  </div>

                  <div onClick={onLogout} className="cursor">
                    <LogoutIcon></LogoutIcon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Sidebar;
