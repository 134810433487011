import { PieChartOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import DateRangeFilter from 'components/DateRangeFilter';
import CustomButton from 'components/UI/CustomButton';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCompanyStats } from 'redux/actions/CompaniesAction';
import AllowedTo from 'utils/AllowedTo';
import { getFromAndTo } from 'utils/utility';
import { Skeleton } from 'antd';

const SubHeader = ({ onDateRangeChange, openModal, loading }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const { selectedDateRange } = useSelector(({ overview }) => overview);
  const [isPopoverOpen, setIsPopoverOpen] = useState();
  const [selectedRange, setSelectedRange] = useState('Last Month');

  const handleSelectRange = (title, date) => {
    const { from, to } = getFromAndTo(date);

    const payload = {
      id: user?.data?.user?.company?.code,
      params: { from, to },
    };

    dispatch(getCompanyStats(payload));
    setSelectedRange(title);

    if (typeof onDateRangeChange === 'function') {
      onDateRangeChange({ from, to });
    }
  };

  useEffect(() => {
    setSelectedRange(selectedDateRange?.val);
  }, [selectedDateRange.range]);

  const openStatementModal = () => {
    setIsPopoverOpen(false);
    openModal();
  };
  const Actions = () => {
    const navigateHandler = (screenType) => () => {
      history.push({
        pathname: screenType,
        state: { isModal: true },
      });
    };
    return (
      <div className="actions-dialog">
        <AllowedTo scopes={['budget-*', 'budget-create']}>
          <div onClick={navigateHandler('/expenses/budgets')} className="actionLink">
            <PieChartOutlined /> Download Report
          </div>
        </AllowedTo>
        <AllowedTo scopes={['employee-create', 'employee-*']}>
          <div onClick={navigateHandler('/people')} className="actionLink">
            <UserOutlined /> Send Report
          </div>
        </AllowedTo>
      </div>
    );
  };

  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  return (
    <div className="d-md-flex align-items-center w-100 justify-content-between pt-4 mb-2">
      <h1 className="page-title mb-0">Reports & Insights</h1>

      <ul className="overview-action p-0 mb-0">
        <li className="p-0" style={{ borderRight: 'unset' }}>
          {loading ? (
            <Skeleton.Button
              active
              shape="square"
              style={{ borderRadius: 8, height: '40px', width: '8rem' }}
            />
          ) : (
            <span>
              <DateRangeFilter
                handleSelectRange={handleSelectRange}
                selectedRange={selectedRange}
                sevenDays={false}
                today={false}
                last30Days={false}
                thisMonth
                lastMonth
                thisQuarter
                lastQuarter
                lastSemester
                lastYear
                customRange
              />
            </span>
          )}
        </li>
        <li style={{ display: 'none' }}>
          <CustomButton className="add-button" onClick={handleTogglePopover}>
            <span>Download Report</span>
            <PlusOutlined
              style={{
                verticalAlign: 0,
                fontSize: 18,
                borderLeftStyle: 'solid',
                borderLeftWidth: 2,
                borderLeftColor: '#09090980',
                paddingLeft: 5,
              }}
            />
          </CustomButton>
        </li>
      </ul>
    </div>
  );
};

export default SubHeader;
