import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scheduledTransactionColumn } from 'utils/mockData';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import {
  CurrencyType,
  ScheduledTransactionStatusType,
} from 'components/FilterModal/FilterHelper';
import Loading from 'components/UI/Loading';
import { Skeleton } from 'antd';
import { useDebounce } from 'hooks/useDebounce';
import { getBudgets } from 'redux/actions/BudgetsAction';
import {
  bulkCancelScheduledTransactions,
  getScheduledTransactions,
  updateScheduledTransactions,
} from 'redux/actions/TransactionsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import {
  buildScheduledTransactionTableData,
  capitalizeFirstLetter,
} from '../../utils/helper';
import ScheduledModal from './components/ScheduledModal';
import TransactionEmptyStateData from './transactionEmptystateData';
import {
  EditPencilIcon,
  EyeIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  XcloseIcon,
} from 'assets/icons';
import EditScheduledTransactionModal from './components/EditScheduledTransactionModal';
import CustomButton from 'components/UI/CustomButton';
import ConfirmDialog from 'components/ConfirmDialog';
import { toastSuccess } from 'components/UI/toast';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import { ReactComponent as PlayDialogIcon } from '../../assets/icons/play.svg';
import PauseScheduledTransactionModal from './components/PauseScheduledTransactionModal';
import ViewScheduledTransactionModal from './components/ViewScheduledTransactionModal';

const ScheduledTransactionsBulkAction = ({ rows, unSetRows }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [numberOfSelectedTransations, setNumberOfSelectedTransations] = useState(0);
  const dispatch = useDispatch();

  const {
    bulkCancelScheduledTransaction: { loading: cancelLoading, success: isCancelSuccess },
  } = useSelector(({ transaction }) => transaction);

  const payload = {
    scheduledTransactions: [],
  };

  const checkFailed = rows.map((item) => item.error);

  useEffect(() => {
    if (isCancelSuccess) {
      setIsSubmit(false);
    }
  }, [isCancelSuccess]);

  const handleConfirm = () => {
    payload.scheduledTransactions = rows.map(({ transactionData: { code } }) => code);
    dispatch(bulkCancelScheduledTransactions(payload));
  };

  const handleSubmit = (action) => {
    setNumberOfSelectedTransations(rows.length);
    setIsSubmit(!isSubmit);
  };

  return (
    <>
      {!!rows.length && (
        <div className="batch-footer position-sticky bottom-0 right-0 bg-black border-top h-auto w-100 bg-white p-3 d-flex justify-content-between align-items-center">
          <div className="text-sm">Total selected {rows.length}</div>
          <div className="d-flex gap-2">
            <CustomButton
              disabled={cancelLoading}
              className="border-btn px-4 text-sm"
              onClick={() => {
                unSetRows();
              }}
            >
              Unselect
            </CustomButton>
            <CustomButton
              withoutBg
              className="customConfirmButton px-4 text-sm"
              onClick={() => handleSubmit('cancel')}
              disabled={!!checkFailed.includes(true)}
            >
              Cancel({rows.length})
            </CustomButton>
          </div>
        </div>
      )}

      <Modal show={isSubmit} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`${capitalizeFirstLetter('cancel')} transaction`}
          subTitle={
            <>
              Are you sure you want to cancel
              <span className="fw-bolder"> {numberOfSelectedTransations}</span>
              {numberOfSelectedTransations > 1 ? ' transactions' : ' transaction'}?
            </>
          }
          onConfirm={handleConfirm}
          onCancel={() => {
            setIsSubmit(false);
          }}
          isDeleteDialog={true}
          actionBtnText="Confirm"
          loading={cancelLoading}
        />
      </Modal>
    </>
  );
};

const ScheduledTransactionTable = ({ handleSelect }) => {
  const [selectTransaction, setSelectTransaction] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [togglePauseModal, setTogglePauseModal] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [toggleDetailsModal, setToggleDetailsModal] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const history = useHistory();
  const [filterData, setFilterData] = useState([
    { title: 'Amount', type: 'slider' },
    ...ScheduledTransactionStatusType,
    ...CurrencyType,
  ]);
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({});
  const [assets, setAssets] = useState({
    active: {},
    assetsList: [],
    visible: false,
  });

  const {
    getScheduledTransaction: {
      data: { meta = {}, scheduledTransactions = [] } = {},
      loading,
    },
    bulkCancelScheduledTransaction: { data: { message } = {}, success: isCancelSuccess },
    updateScheduledTransaction: { loading: isUpdateLoading, success: isUpdateSuccess },
  } = useSelector(({ transaction }) => transaction);

  const { page, total, hasMore, perPage, nextPage } = meta;
  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const { permissions } = allPermissions();
  const canManageBudget = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-create', 'budget-edit'],
  });

  const canViewTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-view'],
  });

  const canViewBudget = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-view'],
  });

  const toggleHandler = () => {
    return history.push('/expenses/budgets');
  };

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    setActionType(type);
    if (type?.toLowerCase() === 'view') {
      setToggleDetailsModal((prevValue) => !prevValue);
      setSelectedOption(value);
    } else if (type?.toLowerCase() === 'edited') {
      setToggle((prevValue) => !prevValue);
      setSelectedOption(value?.transactionData);
    } else if (type?.toLowerCase() === 'pause') {
      setTogglePauseModal((prevValue) => !prevValue);
      setSelectedOption(value);
    } else if (['cancel', 'resume'].includes(type?.toLowerCase())) {
      setConfirmModal((prevValue) => !prevValue);
      setSelectedOption(value?.transactionData);
    }
  };

  const handleRowClick = (row) => {
    setSelectTransaction(row);
    setAssets({
      ...assets,
      assetsList: row?.transactionData?.receipts,
    });
  };

  const closeModal = () => {
    setConfirmModal(false);
    setActionType('');
  };

  const rows = buildScheduledTransactionTableData(scheduledTransactions);

  useEffect(() => {
    if (scheduledTransactions?.length) {
      const allTransactions = scheduledTransactions?.map((transaction) => {
        return {
          value: transaction.payer ? transaction.payer.code : null,
          label: transaction.payer
            ? `${transaction.payer.firstName} ${transaction.payer.lastName}`
            : 'N/A',
          isSelected: false,
        };
      });
      const uniqueArray = allTransactions.filter(
        (initialValue, index, array) =>
          array.findIndex((v2) => v2.value === initialValue.value) === index,
      );

      const newItem = { title: 'Payer', list: uniqueArray };

      if (!filterData.some((item) => item.title === newItem.title)) {
        setFilterData([...filterData, newItem]);
      }
    }
  }, [scheduledTransactions]);

  useEffect(() => {
    if (!!budgetData?.budgets?.length) {
      const data = [];
      budgetData?.budgets.forEach((budget) => {
        const { code, name } = budget;
        data.push({
          value: code,
          label: name,
          isSelected: false,
        });
      });
      setFilterData([...filterData, { title: 'Budget', list: data }]);
    }
  }, [budgetData?.budgets]);

  useEffect(() => {
    if (!scheduledTransactions?.length && !filtered && canViewTransaction) {
      dispatch(getScheduledTransactions());
    }
    if (!budgetData?.budgets && canViewBudget) dispatch(getBudgets());

    return () => {
      if (filtered && canViewTransaction) dispatch(getScheduledTransactions());
    };
  }, [filtered, location]);

  useEffect(() => {
    if (isCancelSuccess) {
      toastSuccess(message);
      dispatch({
        type: RESET_BLOCK_TRANSACTIONS,
        blockType: 'bulkCancelScheduledTransaction',
      });
      dispatch(getScheduledTransactions({ ...filteredQuery }));
    }
  }, [isCancelSuccess]);

  useEffect(() => {
    if (debouncedValue && canViewTransaction) {
      filteredQuery.search = debouncedValue;
      dispatch(getScheduledTransactions({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered && canViewTransaction) {
      delete filteredQuery.search;
      dispatch(getScheduledTransactions({ ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const Actions = ({ list }) => {
    const status = list?.status?.value.toLowerCase();
    const type = status === 'active' ? 'pause' : status === 'pause' && 'resume';
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={(event) => actionHandler(event, 'view', list)}
        >
          <EyeIcon className="mr-4" /> View details
        </div>
        {['active'].includes(status) && (
          <div
            className="actionLink"
            onClick={(event) => actionHandler(event, 'edited', list)}
          >
            <EditPencilIcon /> Edit transaction
          </div>
        )}
        {['active', 'pause'].includes(status) && (
          <>
            <div
              className="actionLink"
              onClick={(event) => actionHandler(event, type, list)}
            >
              {status === 'active' && (
                <>
                  <PauseCircleIcon className="mr-4" /> Pause transaction
                </>
              )}
              {status === 'pause' && (
                <>
                  <PlayCircleIcon className="mr-4" /> Resume transaction
                </>
              )}
            </div>
            <div
              className="actionLink svg-danger text-danger action-link-svg"
              onClick={(event) => actionHandler(event, 'cancel', list)}
            >
              <XcloseIcon stroke="#79716B" width="16" height="16" className="mr-4" />{' '}
              Cancel transaction
            </div>
          </>
        )}
      </div>
    );
  };

  const handleConfirm = () => {
    const code = selectedOption?.code;
    if (actionType === 'cancel') {
      dispatch(updateScheduledTransactions({ code: code, status: 'cancelled' }));
    } else if (actionType === 'pause') {
      dispatch(updateScheduledTransactions({ code: code, status: 'pause' }));
    } else if (actionType === 'resume') {
      dispatch(updateScheduledTransactions({ code: code, status: 'active' }));
    }
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      setSelectedOption(null);
      setConfirmModal(false);
      dispatch(getScheduledTransactions({ ...filteredQuery }));
    }
  }, [isUpdateSuccess]);

  const handleFilter = (query) => {
    const amount = filterData.filter(({ title }) => title === 'Amount');
    const [min, max] = amount[0]?.value || [];
    const min_amount = min ? min * 100 : undefined;
    const max_amount = max ? max * 100 : undefined;
    const queryPayload = {
      ...query,
      min_amount,
      max_amount,
    };

    setFilteredQuery(queryPayload);
    dispatch(getScheduledTransactions(queryPayload));
    isFiltered.current = !!Object.keys(query).length;
  };

  const clearFilters = () => {
    setFilteredQuery({});
    setFilterData([
      { title: 'Amount', type: 'slider' },
      ...ScheduledTransactionStatusType,
      ...CurrencyType,
    ]);
  };

  const handlePreviousPage = (page) => {
    dispatch(getScheduledTransactions({ perPage, page, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getScheduledTransactions({ perPage, page, ...filteredQuery }));
  };

  const show =
    !!scheduledTransactions?.length || (filtered && !scheduledTransactions?.length);

  const loadingState = loading && !filtered;

  const Loader = () => (
    <section className="pt-3 fade-in">
      <div className="d-flex">
        <Skeleton.Button
          active
          shape="square"
          style={{ borderRadius: 8, height: '40px', width: '16rem' }}
        />

        <div className="ms-auto d-flex gap-2">
          <Skeleton.Button
            active
            shape="square"
            style={{ borderRadius: 8, height: '40px', width: '7rem' }}
          />
        </div>
      </div>

      <div className="mt-4">
        <Table
          columns={scheduledTransactionColumn}
          data={rows}
          hasCheckBox={false}
          loading
        />
      </div>
    </section>
  );

  return (
    <>
      {loadingState ? (
        <Loader />
      ) : (
        <>
          <TopBar
            showFilter={show}
            addOnClick={toggleHandler}
            inputPlaceholder="Search"
            filterData={filterData}
            handleFilterSelect={(updateVal) => {
              setFilterData(updateVal);
            }}
            searchVal={search}
            setSearchVal={setSearch}
            showBarSearch={show}
            handleFilterApply={handleFilter}
            withDate
            dateTitle="Transaction Date"
            clearFilters={clearFilters}
          />
          {!scheduledTransactions.length ? (
            <div>
              {isFiltered.current ? (
                <div className="tabinnerWrapper">
                  <NoData
                    headerText={`You have no scheduled transactions for this filter`}
                    bodyText="Alter filter to see scheduled transactions"
                    withButton={false}
                  />
                </div>
              ) : (
                <TransactionEmptyStateData handleSelect={handleSelect} />
              )}
            </div>
          ) : (
            <>
              <Container className="px-0">
                <Row className="py-4">
                  <Col xs={12} className="spaced-table">
                    <Table
                      columns={scheduledTransactionColumn}
                      data={rows}
                      pagination
                      onRowClick={handleRowClick}
                      hasCheckBox={loadingState ? false : true}
                      hasMore={hasMore}
                      currentPage={page}
                      nextPage={() => handleNextPage(nextPage)}
                      previousPage={() => handlePreviousPage(page - 1)}
                      totalPage={Math.ceil(total / perPage)}
                      popoverAction={Actions}
                      popoverState={isPopoverOpen}
                      setPopoverState={setIsPopoverOpen}
                      getSelectedRows={ScheduledTransactionsBulkAction}
                      loading={loadingState}
                    />
                  </Col>
                </Row>
              </Container>
              {selectTransaction && (
                <ScheduledModal
                  selectedTransaction={selectTransaction}
                  setSelectedTransaction={setSelectTransaction}
                />
              )}
            </>
          )}
        </>
      )}

      {toggle && (
        <EditScheduledTransactionModal
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          filter={filteredQuery}
          setToggle={setToggle}
          toggle={toggle}
        />
      )}

      {togglePauseModal && (
        <PauseScheduledTransactionModal
          selectedTransaction={selectedOption}
          setSelectedTransaction={setSelectedOption}
          filter={filteredQuery}
          setToggle={setTogglePauseModal}
          toggle={togglePauseModal}
        />
      )}

      {toggleDetailsModal && (
        <ViewScheduledTransactionModal
          selectedTransaction={selectedOption}
          setSelectedTransaction={setSelectedOption}
          filter={filteredQuery}
          setToggle={setToggleDetailsModal}
          toggle={toggleDetailsModal}
        />
      )}

      <Modal
        show={confirmModal && actionType === 'cancel'}
        centered
        dialogClassName="custom-dialog"
      >
        <ConfirmDialog
          title={`${capitalizeFirstLetter(actionType)} transaction`}
          subTitle={`Are you sure you want to ${actionType} this transaction?`}
          onConfirm={handleConfirm}
          onCancel={closeModal}
          isDeleteDialog={true}
          actionBtnText="Confirm"
          loading={isUpdateLoading}
        />
      </Modal>

      <Modal
        show={confirmModal && actionType === 'resume'}
        centered
        dialogClassName="custom-dialog"
      >
        <ConfirmDialog
          title={`${capitalizeFirstLetter(actionType)} transaction`}
          subTitle={`Are you sure you want to ${actionType} this transaction?`}
          onConfirm={handleConfirm}
          onCancel={closeModal}
          isDeleteDialog={false}
          dialogIcon={<PlayDialogIcon />}
          actionBtnText="Confirm"
          loading={isUpdateLoading}
        />
      </Modal>
    </>
  );
};

export default ScheduledTransactionTable;
