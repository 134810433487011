import {
  CREATE_ASSET_REQUEST,
  DOWNLOAD_ASSET_REQUEST,
  GET_ASSET_REQUEST,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE,
  UPLOAD_FILE_PROGRESS,
} from '../reducers/AssetsReducer';

export const getAssets = (payload) => ({
  type: GET_ASSET_REQUEST,
  payload,
});
export const downloadAsset = (payload) => ({
  type: DOWNLOAD_ASSET_REQUEST,
  payload,
});

export const createAssets = (payload, scan = false) => ({
  type: CREATE_ASSET_REQUEST,
  payload,
  scan,
});

export const uploadFiles = (payload) => ({ type: UPLOAD_FILE_REQUEST, payload });

export const uploadFileData = (payload) => ({ type: UPLOAD_FILE, payload });

export const uploadProgress = (payload) => ({
  type: UPLOAD_FILE_PROGRESS,
  payload,
});
