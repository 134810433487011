import { useDebounce } from 'hooks/useDebounce';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from 'redux/actions/CategoryAction';
import CustomSelect from '.';
import { flattenCategories } from 'utils/helper';

const CategoryDropdown = ({
  value,
  onChange,
  name,
  label,
  categoryFilter = () => null,
  placeholder,
  disabled,
  defaultValue,
  customStyles,
  hasError = false,
  ...res
}) => {
  const [inputValue, setInputValue] = useState({ [name]: '' });
  const debouncedValue = useDebounce(inputValue[name], 200);

  const handleSelectChange = (val, type) => setInputValue({ ...inputValue, [type]: val });
  const dispatch = useDispatch();

  const {
    fetchCategories: { data: { categories = [] } = {}, loading, success },
  } = useSelector(({ categories }) => categories);

  useEffect(() => {
    if (!debouncedValue) dispatch(fetchCategories());
  }, [debouncedValue]);

  const generatedCategories = useMemo(() => {
    const data = flattenCategories(categoryFilter(categories) || categories);
    return data?.map(({ code: value, name: label }) => ({
      label,
      value,
    }));
  }, [categories?.length]);

  useEffect(() => {
    const categoriesList = generatedCategories?.find((option) =>
      option.label.toLowerCase().includes(debouncedValue.toLowerCase()),
    );
    if (!categoriesList && debouncedValue) {
      dispatch(fetchCategories({ search: debouncedValue?.toLowerCase() }));
    }
  }, [debouncedValue]);

  const handleChange = ({ name, value }) => {
    onChange(value);
  };

  const onMenuClose = () => {
    if (debouncedValue) dispatch(fetchCategories());
  };

  return (
    <Fragment>
      <CustomSelect
        onMenuClose={onMenuClose}
        label={label}
        placeholder={placeholder}
        name={name}
        options={generatedCategories}
        onChange={(value) => handleChange({ value })}
        value={value}
        onInputChange={(value) => handleSelectChange(value, name)}
        isDisabled={disabled}
        isLoading={loading}
        defaultValue={defaultValue}
        hasError={hasError}
        customStyles={customStyles}
        {...res}
      />
    </Fragment>
  );
};

export default CategoryDropdown;
