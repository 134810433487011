import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { RESET_BLOCK_ASSET } from 'redux/reducers/AssetsReducer';
import {
  BULK_VERIFY_BANK_ACCOUNTS_ERROR,
  BULK_VERIFY_BANK_ACCOUNTS_REQUEST,
  BULK_VERIFY_BANK_ACCOUNTS_SUCCESS,
  CREATE_BATCH_PAYMENTS_ERROR,
  CREATE_BATCH_PAYMENTS_REQUEST,
  CREATE_BATCH_PAYMENTS_SUCCESS,
  CREATE_PAYMENTS_ERROR,
  CREATE_PAYMENTS_REQUEST,
  CREATE_PAYMENTS_SUCCESS,
  GET_ALL_BANKS_ERROR,
  GET_ALL_BANKS_REQUEST,
  GET_ALL_BANKS_SUCCESS,
  GET_BATCH_PAYMENTS_ERROR,
  GET_BATCH_PAYMENTS_REQUEST,
  GET_BATCH_PAYMENTS_SUCCESS,
  GET_DIRECT_DEBIT_BANKS_ERROR,
  GET_DIRECT_DEBIT_BANKS_REQUEST,
  GET_DIRECT_DEBIT_BANKS_SUCCESS,
  RESET_FLAGS_PAYMENTS,
  VERIFY_BANK_ACCOUNT_ERROR,
  VERIFY_BANK_ACCOUNT_REQUEST,
  VERIFY_BANK_ACCOUNT_SUCCESS,
  LOG_EXPENSE_REQUEST,
  LOG_EXPENSE_SUCCESS,
  LOG_EXPENSE_ERROR,
  PAY_NOW_ACTION_SUCCESS,
  PAY_NOW_ACTION_ERROR,
  PAY_NOW_ACTION_REQUEST,
  RESET_BLOCK_PAYMENTS,
  GET_SINGLE_BATCH_PAYMENT_SUCCESS,
  GET_SINGLE_BATCH_PAYMENT_ERROR,
  GET_SINGLE_BATCH_PAYMENT_REQUEST,
  UPDATE_BATCH_PAYMENT_ASSET_REQUEST,
  UPDATE_BATCH_PAYMENT_ASSET_SUCCESS,
  UPDATE_BATCH_PAYMENT_ASSET_ERROR,
  CREATE_BULK_PAYMENT_SUCCESS,
  CREATE_BULK_PAYMENT_ERROR,
  CREATE_BULK_PAYMENT_REQUEST,
} from 'redux/reducers/PaymentReducer';
import { getSimplifiedError } from 'utils/error';

async function createPayment(payload) {
  return await Axios.post(`/payments`, payload);
}
function* handleCreatePayment({ payload }) {
  try {
    const response = yield call(createPayment, payload);
    if (response) {
      yield put({
        type: CREATE_PAYMENTS_SUCCESS,
        data: response.data,
      });

      yield delay(200);
      yield put({
        type: RESET_BLOCK_ASSET,
        blockType: 'createAsset',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_PAYMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function logExpense(payload) {
  return await Axios.post(`/payments/log`, payload);
}
function* handleLogExpense({ payload }) {
  try {
    const response = yield call(logExpense, payload);
    if (response) {
      yield put({
        type: LOG_EXPENSE_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_FLAGS_PAYMENTS,
        blockType: 'logExpense',
      });
      yield put({
        type: RESET_BLOCK_ASSET,
        blockType: 'createAsset',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: LOG_EXPENSE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createBatchPayment(payload) {
  return await Axios.post(`/payments/batch`, payload);
}
function* handleCreateBatchPayment({ payload }) {
  try {
    const response = yield call(createBatchPayment, payload);
    if (response) {
      yield put({
        type: CREATE_BATCH_PAYMENTS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_FLAGS_PAYMENTS,
        blockType: 'createBatchPayment',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_BATCH_PAYMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createBulkPayment(payload) {
  return await Axios.post(`/transactions/bulk-pay`, payload);
}
function* handleCreateBulkPayment({ payload }) {
  try {
    const response = yield call(createBulkPayment, payload);
    if (response) {
      yield put({
        type: CREATE_BULK_PAYMENT_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_PAYMENTS,
        blockType: 'createBulkPayment',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_BULK_PAYMENT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getBatchPayment(payload) {
  return await Axios.get(`/payments/batch`, { params: payload });
}
function* handleGetBatchPayment({ payload }) {
  try {
    const response = yield call(getBatchPayment, payload);
    if (response) {
      yield put({
        type: GET_BATCH_PAYMENTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_BATCH_PAYMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export async function verifyBankAccount(payload) {
  return await Axios.post(`/banks/verify/account`, payload, { timeout: 150000 });
}
function* handleVerifyBankAccount({ payload }) {
  try {
    const response = yield call(verifyBankAccount, payload);
    if (response) {
      yield put({
        type: VERIFY_BANK_ACCOUNT_SUCCESS,
        data: response.data,
      });
      // yield put({
      //   type: RESET_FLAGS_PAYMENTS,
      //   blockType: 'verifyBankAccount',
      // });
      // yield put({
      //   type: RESET_BLOCK_ASSET,
      //   blockType: 'verifyBankAccount',
      // });
    }
  } catch (error) {
    yield put({
      type: VERIFY_BANK_ACCOUNT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export async function bulkVerifyBankAccounts(payload) {
  return await Axios.post(`/banks/account/bulk-verify`, payload);
}
function* handleBulkVerifyBankAccounts({ payload }) {
  try {
    const response = yield call(bulkVerifyBankAccounts, payload);
    if (response) {
      yield put({
        type: BULK_VERIFY_BANK_ACCOUNTS_SUCCESS,
        data: response.data,
      });
      // yield put({
      //   type: RESET_FLAGS_PAYMENTS,
      //   blockType: 'verifyBankAccount',
      // });
      // yield put({
      //   type: RESET_BLOCK_ASSET,
      //   blockType: 'verifyBankAccount',
      // });
    }
  } catch (error) {
    yield put({
      type: BULK_VERIFY_BANK_ACCOUNTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getAllBanks(params) {
  return await Axios.get(`/utils/banks`, { params });
}
function* handleGetAllBanks({ payload }) {
  try {
    const response = yield call(getAllBanks, payload);
    if (response) {
      yield put({
        type: GET_ALL_BANKS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_ALL_BANKS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getDirectDebitBanks(params) {
  return await Axios.get(`/utils/banks/direct-debit`, { params });
}
function* handleGetDirectDebitBanks({ payload }) {
  try {
    const response = yield call(getDirectDebitBanks, payload);
    if (response) {
      yield put({
        type: GET_DIRECT_DEBIT_BANKS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_DIRECT_DEBIT_BANKS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function payNowAction(payload) {
  const { code, ...rest } = payload;
  return await Axios.post(`/transactions/${code}/pay`, rest);
}
function* handlePayNowAction({ payload }) {
  try {
    const response = yield call(payNowAction, payload);
    if (response) {
      yield put({
        type: PAY_NOW_ACTION_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(400);
      yield put({
        type: RESET_BLOCK_PAYMENTS,
        blockType: 'payNowAction',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: PAY_NOW_ACTION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleBatchPayment(payload) {
  return await Axios.get(`/batch/${payload}`);
}
function* handleGetSingleBatchPayment({ payload }) {
  try {
    const response = yield call(getSingleBatchPayment, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_BATCH_PAYMENT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_BATCH_PAYMENT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateBatchPaymentAsset(payload) {
  const { code, ...rest } = payload;
  return await Axios.put(`/batch/${code}/assets`, rest);
}
function* handleUpdateBatchPaymentAsset({ payload }) {
  try {
    const response = yield call(updateBatchPaymentAsset, payload);
    if (response) {
      yield put({
        type: UPDATE_BATCH_PAYMENT_ASSET_SUCCESS,
        data: response.data,
      });

      toastSuccess(response.message);

      yield delay(400);
      yield put({
        type: RESET_BLOCK_PAYMENTS,
        blockType: 'updateBatchPaymentAsset',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_BATCH_PAYMENT_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(CREATE_PAYMENTS_REQUEST, handleCreatePayment),
  takeLatest(CREATE_BATCH_PAYMENTS_REQUEST, handleCreateBatchPayment),
  takeLatest(CREATE_BULK_PAYMENT_REQUEST, handleCreateBulkPayment),
  takeLatest(GET_BATCH_PAYMENTS_REQUEST, handleGetBatchPayment),
  takeLatest(VERIFY_BANK_ACCOUNT_REQUEST, handleVerifyBankAccount),
  takeLatest(BULK_VERIFY_BANK_ACCOUNTS_REQUEST, handleBulkVerifyBankAccounts),
  takeLatest(GET_ALL_BANKS_REQUEST, handleGetAllBanks),
  takeLatest(GET_DIRECT_DEBIT_BANKS_REQUEST, handleGetDirectDebitBanks),
  takeLatest(LOG_EXPENSE_REQUEST, handleLogExpense),
  takeLatest(PAY_NOW_ACTION_REQUEST, handlePayNowAction),
  takeLatest(GET_SINGLE_BATCH_PAYMENT_REQUEST, handleGetSingleBatchPayment),
  takeLatest(UPDATE_BATCH_PAYMENT_ASSET_REQUEST, handleUpdateBatchPaymentAsset),
]);
