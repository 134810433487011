export const CREATE_PAYMENTS_REQUEST = 'CREATE_PAYMENT_REQUEST';
export const CREATE_PAYMENTS_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENTS_ERROR = 'CREATE_PAYMENT_ERROR';

export const PAY_NOW_ACTION_REQUEST = 'PAY_NOW_ACTION_REQUEST';
export const PAY_NOW_ACTION_SUCCESS = 'PAY_NOW_ACTION_SUCCESS';
export const PAY_NOW_ACTION_ERROR = 'PAY_NOW_ACTION_ERROR';

export const CREATE_BATCH_PAYMENTS_REQUEST = 'CREATE_BATCH_PAYMENT_REQUEST';
export const CREATE_BATCH_PAYMENTS_SUCCESS = 'CREATE_BATCH_PAYMENT_SUCCESS';
export const CREATE_BATCH_PAYMENTS_ERROR = 'CREATE_BATCH_PAYMENT_ERROR';

export const GET_BATCH_PAYMENTS_REQUEST = 'GET_BATCH_PAYMENT_REQUEST';
export const GET_BATCH_PAYMENTS_SUCCESS = 'GET_BATCH_PAYMENT_SUCCESS';
export const GET_BATCH_PAYMENTS_ERROR = 'GET_BATCH_PAYMENT_ERROR';

export const GET_SINGLE_BATCH_PAYMENT_REQUEST = 'GET_SINGLE_BATCH_PAYMENT_REQUEST';
export const GET_SINGLE_BATCH_PAYMENT_SUCCESS = 'GET_SINGLE_BATCH_PAYMENT_SUCCESS';
export const GET_SINGLE_BATCH_PAYMENT_ERROR = 'GET_SINGLE_BATCH_PAYMENT_ERROR';

export const VERIFY_BANK_ACCOUNT_REQUEST = 'VERIFY_BANK_ACCOUNT_REQUEST';
export const VERIFY_BANK_ACCOUNT_SUCCESS = 'VERIFY_BANK_ACCOUNT_SUCCESS';
export const VERIFY_BANK_ACCOUNT_ERROR = 'VERIFY_BANK_ACCOUNT_ERROR';

export const GET_ALL_BANKS_REQUEST = 'GET_ALL_BANKS_REQUEST';
export const GET_ALL_BANKS_SUCCESS = 'GET_ALL_BANKS_SUCCESS';
export const GET_ALL_BANKS_ERROR = 'GET_ALL_BANKS_ERROR';

export const GET_DIRECT_DEBIT_BANKS_REQUEST = 'GET_DIRECT_DEBIT_BANKS_REQUEST';
export const GET_DIRECT_DEBIT_BANKS_SUCCESS = 'GET_DIRECT_DEBIT_BANKS_SUCCESS';
export const GET_DIRECT_DEBIT_BANKS_ERROR = 'GET_DIRECT_DEBIT_BANKS_ERROR';

export const UPDATE_BATCH_PAYMENT_ASSET_REQUEST = 'UPDATE_BATCH_PAYMENT_ASSET_REQUEST';
export const UPDATE_BATCH_PAYMENT_ASSET_SUCCESS = 'UPDATE_BATCH_PAYMENT_ASSET_SUCCESS';
export const UPDATE_BATCH_PAYMENT_ASSET_ERROR = 'UPDATE_BATCH_PAYMENT_ASSET_ERROR';

export const LOG_EXPENSE_REQUEST = 'LOG_EXPENSE_REQUEST';
export const LOG_EXPENSE_SUCCESS = 'LOG_EXPENSE_SUCCESS';
export const LOG_EXPENSE_ERROR = 'LOG_EXPENSE_ERROR';

export const BULK_VERIFY_BANK_ACCOUNTS_REQUEST = 'BULK_VERIFY_BANK_ACCOUNTS_REQUEST';
export const BULK_VERIFY_BANK_ACCOUNTS_SUCCESS = 'BULK_VERIFY_BANK_ACCOUNTS_SUCCESS';
export const BULK_VERIFY_BANK_ACCOUNTS_ERROR = 'BULK_VERIFY_BANK_ACCOUNTS_ERROR';

export const CREATE_BULK_PAYMENT_REQUEST = 'CREATE_BULK_PAYMENT_REQUEST';
export const CREATE_BULK_PAYMENT_SUCCESS = 'CREATE_BULK_PAYMENT_SUCCESS';
export const CREATE_BULK_PAYMENT_ERROR = 'CREATE_BULK_PAYMENT_ERROR';

export const RESET_BLOCK_PAYMENTS = 'RESET_BLOCK_PAYMENT';

export const RESET_FLAGS_PAYMENTS = 'RESET_FLAGS_PAYMENT';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  createPayment: { ...block },
  verifyBankAccount: { ...block },
  bulkVerifyBankAccounts: { ...block },
  createBatchPayment: { ...block },
  getBatchPayment: { ...block },
  getSingleBatchPayment: { ...block },
  updateBatchPaymentAsset: { ...block },
  createBulkPayment: { ...block },
  getAllBanks: { ...block },
  getDirectDebitBanks: { ...block },
  logExpense: { ...block },
  payNowAction: { ...block },
};

export const PaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PAYMENTS_REQUEST:
      return { ...state, createPayment: { ...state.createPayment, loading: true } };
    case CREATE_PAYMENTS_SUCCESS:
      return {
        ...state,
        createPayment: {
          ...state.createPayment,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_PAYMENTS_ERROR:
      return {
        ...state,
        createPayment: { ...state.createPayment, loading: false, error: action.error },
      };

    case CREATE_BULK_PAYMENT_REQUEST:
      return {
        ...state,
        createBulkPayment: { ...state.createBulkPayment, loading: true },
      };
    case CREATE_BULK_PAYMENT_SUCCESS:
      return {
        ...state,
        createBulkPayment: {
          ...state.createBulkPayment,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_BULK_PAYMENT_ERROR:
      return {
        ...state,
        createBulkPayment: {
          ...state.createBulkPayment,
          loading: false,
          error: action.error,
        },
      };

    case PAY_NOW_ACTION_REQUEST:
      return { ...state, payNowAction: { ...state.payNowAction, loading: true } };
    case PAY_NOW_ACTION_SUCCESS:
      return {
        ...state,
        payNowAction: {
          ...state.payNowAction,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case PAY_NOW_ACTION_ERROR:
      return {
        ...state,
        payNowAction: { ...state.payNowAction, loading: false, error: action.error },
      };

    case LOG_EXPENSE_REQUEST:
      return { ...state, logExpense: { ...state.logExpense, loading: true } };
    case LOG_EXPENSE_SUCCESS:
      return {
        ...state,
        logExpense: {
          ...state.logExpense,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case LOG_EXPENSE_ERROR:
      return {
        ...state,
        logExpense: { ...state.logExpense, loading: false, error: action.error },
      };

    case CREATE_BATCH_PAYMENTS_REQUEST:
      return {
        ...state,
        createBatchPayment: { ...state.createBatchPayment, loading: true },
      };
    case CREATE_BATCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        createBatchPayment: {
          ...state.createBatchPayment,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_BATCH_PAYMENTS_ERROR:
      return {
        ...state,
        createBatchPayment: {
          ...state.createBatchPayment,
          loading: false,
          error: action.error,
        },
      };

    case GET_BATCH_PAYMENTS_REQUEST:
      return {
        ...state,
        getBatchPayment: { ...state.getBatchPayment, loading: true },
      };
    case GET_BATCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        getBatchPayment: {
          ...state.getBatchPayment,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_BATCH_PAYMENTS_ERROR:
      return {
        ...state,
        getBatchPayment: {
          ...state.getBatchPayment,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_BATCH_PAYMENT_REQUEST:
      return {
        ...state,
        getSingleBatchPayment: { ...state.getSingleBatchPayment, loading: true },
      };
    case GET_SINGLE_BATCH_PAYMENT_SUCCESS:
      return {
        ...state,
        getSingleBatchPayment: {
          ...state.getSingleBatchPayment,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_BATCH_PAYMENT_ERROR:
      return {
        ...state,
        getSingleBatchPayment: {
          ...state.getSingleBatchPayment,
          loading: false,
          error: action.error,
        },
      };

    case VERIFY_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        verifyBankAccount: { ...state.verifyBankAccount, loading: true },
      };
    case VERIFY_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        verifyBankAccount: {
          ...state.verifyBankAccount,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case VERIFY_BANK_ACCOUNT_ERROR:
      return {
        ...state,
        verifyBankAccount: {
          ...state.verifyBankAccount,
          loading: false,
          error: action.error,
        },
      };

    case BULK_VERIFY_BANK_ACCOUNTS_REQUEST:
      return {
        ...state,
        bulkVerifyBankAccounts: { ...state.bulkVerifyBankAccounts, loading: true },
      };
    case BULK_VERIFY_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        bulkVerifyBankAccounts: {
          ...state.bulkVerifyBankAccounts,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case BULK_VERIFY_BANK_ACCOUNTS_ERROR:
      return {
        ...state,
        bulkVerifyBankAccounts: {
          ...state.bulkVerifyBankAccounts,
          loading: false,
          error: action.error,
        },
      };

    case GET_ALL_BANKS_REQUEST:
      return {
        ...state,
        getAllBanks: { ...state.getAllBanks, loading: true },
      };
    case GET_ALL_BANKS_SUCCESS:
      return {
        ...state,
        getAllBanks: {
          ...state.getAllBanks,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_ALL_BANKS_ERROR:
      return {
        ...state,
        getAllBanks: {
          ...state.getAllBanks,
          loading: false,
          error: action.error,
        },
      };

    case GET_DIRECT_DEBIT_BANKS_REQUEST:
      return {
        ...state,
        getDirectDebitBanks: { ...state.getDirectDebitBanks, loading: true },
      };
    case GET_DIRECT_DEBIT_BANKS_SUCCESS:
      return {
        ...state,
        getDirectDebitBanks: {
          ...state.getDirectDebitBanks,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_DIRECT_DEBIT_BANKS_ERROR:
      return {
        ...state,
        getDirectDebitBanks: {
          ...state.getDirectDebitBanks,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_BATCH_PAYMENT_ASSET_REQUEST:
      return {
        ...state,
        updateBatchPaymentAsset: { ...state.updateBatchPaymentAsset, loading: true },
      };
    case UPDATE_BATCH_PAYMENT_ASSET_SUCCESS:
      return {
        ...state,
        updateBatchPaymentAsset: {
          ...state.updateBatchPaymentAsset,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_BATCH_PAYMENT_ASSET_ERROR:
      return {
        ...state,
        updateBatchPaymentAsset: {
          ...state.updateBatchPaymentAsset,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_PAYMENTS:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    //reset only flags(block)
    case RESET_FLAGS_PAYMENTS:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
