import { useHistory } from 'react-router-dom';

import EmptyState from 'components/UI/EmptyState';
import CustomButton from 'pages/Profile/Components/CustomButton';

import '../index.scss';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Send, File } from 'assets/icons/empty-state-icons';
import BillsImage from '../../../assets/images/emptystate/bills.svg';

const BillsEmptyState = () => {
  const history = useHistory();
  const routeTo = () => {
    history.push('/expenses/bills/create');
  };

  const mainData = {
    title: 'Review and manage your bills with ease.',
    body: (
      <div style={{ width: '85%' }}>
        Manage your bills, upload or send your details to our email at
        <a href="mailto:bills@bujeti.com" className="inner-link">
          {' '}
          bills@bujeti.com{' '}
        </a>
        See how it works
        <span onClick={routeTo} className="inner-link">
          {' '}
          here
        </span>
        .
      </div>
    ),

    image: BillsImage,
    action: () => (
      <CustomButton
        className={'dark-button d-flex align-items-center gap-2 w-auto'}
        style={{ padding: '10px 16px', borderRadius: 8 }}
        onClick={routeTo}
      >
        <PlusOutlined
          style={{
            verticalAlign: 0,
            fontSize: 18,
          }}
        />
        <span className="ms-1" style={{ fontWeight: 500 }}>
          Add a bill{' '}
        </span>
      </CustomButton>
    ),
  };

  const childrenData = [
    {
      title: 'Upload your invoice',
      body: 'Upload your invoice and validate your details before submission',
      actionTitle: 'View more',
      icon: <Upload />,
    },

    {
      title: 'Forward your invoice to our mail',
      body: 'Copy our email and send your invoice. It will show up in your dratfs',
      actionTitle: 'View more',
      icon: <Send />,
    },
    {
      title: 'Track your expenses',
      body: 'Adequately track and manage your bills across categories ',
      actionTitle: 'View more',
      icon: <File />,
    },
  ];

  return <EmptyState main={mainData} childrenData={childrenData} />;
};

export default BillsEmptyState;
