import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'react-bootstrap';

import DeleteDialog from 'components/DeleteDialog';
import TopBar from 'components/TopBar';
import AllBills from './components/Tables/All';
import DraftBills from './components/Tables/Draft';
import ArchivedBills from './components/Tables/Archived';
import ScheduledBills from './components/Tables/Scheduled';
import BillAnalytics from './components/Analytics';
import NoData from 'components/NoData';
import BillsEmptyState from './components/EmptyState';
import CustomButton from 'pages/Profile/Components/CustomButton';

import { RESET_FLAGS_BILL } from 'redux/reducers/BillReducer';

import {
  fetchBills,
  fetchDraftBills,
  fetchScheduledBills,
  deleteBill,
  deleteScheduledBill,
  fetchArchivedBills,
} from 'redux/actions/BillAction';

import './index.scss';
import { Tab, Tabs } from 'react-bootstrap';
import { PlusOutlined } from '@ant-design/icons';
import { InfoCircle02, XcloseIcon } from 'assets/icons/index';

const canCreate = () => {};

const Bills = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [deleteModal, setDeleteModal] = useState(null);
  const [filteredQuery, setFilteredQuery] = useState({});

  const locationArray = location.pathname.split('/');
  const [key, setKey] = useState(locationArray[locationArray.length - 1]);

  const {
    fetchBills: { loading, success, data },
    draftBills: { loading: draftLoading, success: draftSuccess, data: draft },
    fetchScheduledBills: {
      loading: scheduleLoading,
      success: scheduleSuccess,
      data: scheduledBill,
    },

    archivedBills: {
      loading: archiveLoading,
      success: archiveSuccess,
      data: archivedBill,
    },

    deleteBill: { loading: isDeleting, success: deleteSuccess },
    deleteScheduledBill: { loading: isDeletingSchedule, success: deleteScheduleSuccess },
  } = useSelector(({ bills }) => bills);

  useEffect(() => {
    if (!data?.bills?.length) {
      dispatch(fetchBills());
      dispatch(fetchDraftBills());
      dispatch(fetchScheduledBills());
      dispatch(fetchArchivedBills());
    }
  }, []);

  useEffect(() => {
    if (deleteSuccess || deleteScheduleSuccess) {
      setDeleteModal(null);
      dispatch(fetchBills());
      dispatch({ type: RESET_FLAGS_BILL, blockType: 'deleteBill' });
      dispatch({ type: RESET_FLAGS_BILL, blockType: 'deleteScheduledBill' });
    }
  }, [deleteSuccess, deleteScheduleSuccess]);

  const hasQuery = !!Object.keys(filteredQuery || {}).length;

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    if (tabKey !== 'bills') {
      history.push(`/expenses/bills/${tabKey}`);
    } else {
      history.push(`/expenses/bills`);
    }
  };

  const handleDelete = () => {
    const isSchedule = deleteModal?.split('_')[0] === 'sbl';

    const action = isSchedule
      ? deleteScheduledBill(deleteModal)
      : deleteBill(deleteModal);

    dispatch(action);
  };

  const clearLocationState = () => {
    switch (key) {
      case 'scheduled':
        return dispatch(fetchScheduledBills());

      case 'drafts':
        return dispatch(fetchDraftBills());

      case 'archive':
        return dispatch(fetchArchivedBills());

      default:
        return dispatch(fetchBills());
    }
  };

  const ActionButtons = () => {
    return (
      <div className="d-flex px-2 gap-3">
        <button
          className={'add-custom request-btn d-flex align-items-center gap-2'}
          style={{ padding: '10px 16px', borderRadius: 8 }}
          onClick={() => {
            history.push('/expenses/bills/create');
          }}
        >
          <span className="ms-1" style={{ fontWeight: 500 }}>
            Request funds
          </span>
        </button>

        <CustomButton
          className={'dark-button d-flex align-items-center gap-2'}
          style={{ padding: '10px 16px', borderRadius: 8 }}
          onClick={() => {
            history.push('/expenses/bills/create');
          }}
        >
          <PlusOutlined
            style={{
              verticalAlign: 0,
              fontSize: 18,
            }}
          />
          <span className="ms-1" style={{ fontWeight: 500 }}>
            Add a bill{' '}
          </span>
        </CustomButton>
      </div>
    );
  };

  const EmptyData = () => {
    return (
      <div className="mt-5">
        <NoData
          headerText="You have no bill for this filter"
          bodyText="There are no bills for this filter, use the button below to show all your available bills"
          withButton={true}
          buttonLabel={
            <span className="d-flex justify-content-center">Show all bills</span>
          }
          onClickHandler={clearLocationState}
        />
      </div>
    );
  };

  const renderBills = () => {
    switch (key) {
      case 'drafts':
        return (
          <>
            <BillAnalytics data={data} loading={draftLoading} />
            {!!draft?.bills?.length || draftLoading ? (
              <DraftBills
                data={draft}
                loading={draftLoading}
                handleDelete={(code) => setDeleteModal(code)}
              />
            ) : (
              <EmptyData />
            )}
          </>
        );

      case 'scheduled':
        return (
          <>
            <BillAnalytics data={data} loading={scheduleLoading} />
            {!!scheduledBill?.scheduledBills?.length || scheduleLoading ? (
              <ScheduledBills
                data={scheduledBill}
                loading={scheduleLoading}
                handleDelete={(code) => setDeleteModal(code)}
              />
            ) : (
              <EmptyData />
            )}
          </>
        );

      case 'archive':
        return (
          <>
            <BillAnalytics data={data} loading={archiveLoading} />
            {!!archivedBill?.bills?.length || archiveLoading ? (
              <ArchivedBills data={archivedBill} loading={archiveLoading} />
            ) : (
              <EmptyData />
            )}
          </>
        );

      default:
        return (
          <>
            <BillAnalytics data={data} loading={loading} hasQuery={hasQuery} />
            {!!data?.bills?.length || loading ? (
              <>
                <AllBills
                  data={data}
                  loading={loading}
                  handleDelete={(code) => setDeleteModal(code)}
                  filteredQuery={filteredQuery}
                  setFilteredQuery={(data) => setFilteredQuery(data)}
                />
              </>
            ) : hasQuery ? (
              <EmptyData />
            ) : (
              <BillsEmptyState />
            )}
          </>
        );
    }
  };

  return (
    <>
      <div className="budgets-wrapper bills position-relative mb-5">
        <TopBar
          headerText="Bills"
          subText="Organize and access your bills"
          isRightBar
          addButton
          addActionButton={<ActionButtons />}
          // toggleActionButton={toggleActionButton}
        />

        <div className="mt-3">
          <section className="container">
            {!!data?.bills?.length || hasQuery ? (
              <>
                <Banner />

                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={handleSelect}
                  className="mb-2 mt-4"
                >
                  <Tab
                    eventKey="bills"
                    title={
                      <div className="d-flex">
                        All{' '}
                        <div className="count">
                          <span className="m-auto">{data?.meta?.total || 0}</span>
                        </div>
                      </div>
                    }
                    tabClassName="new-tab"
                  ></Tab>

                  <Tab
                    eventKey="scheduled"
                    title={
                      <div className="d-flex">
                        Scheduled{' '}
                        <div className="count">
                          <span className="m-auto">
                            {scheduledBill?.meta?.total || 0}
                          </span>
                        </div>
                      </div>
                    }
                    tabClassName="new-tab"
                  ></Tab>

                  <Tab
                    eventKey="drafts"
                    title={
                      <div className="d-flex">
                        Drafts{' '}
                        <div className="count">
                          <span className="m-auto">{draft?.meta?.total || 0}</span>
                        </div>
                      </div>
                    }
                    tabClassName="new-tab"
                  ></Tab>

                  <Tab
                    eventKey="archive"
                    title={
                      <div className="d-flex">
                        Archived{' '}
                        <div className="count">
                          <span className="m-auto">{archivedBill?.meta?.total || 0}</span>
                        </div>
                      </div>
                    }
                    tabClassName="new-tab"
                  ></Tab>
                </Tabs>
              </>
            ) : null}

            {renderBills()}
          </section>
        </div>
      </div>

      <Modal show={!!deleteModal} centered dialogClassName="custom-dialog">
        <DeleteDialog
          title="Archive bill"
          subTitle="Are you sure you want archive this bill?"
          onCancel={() => setDeleteModal(null)}
          onDelete={handleDelete}
          deleteText="Archive"
          isLoading={isDeleting || isDeletingSchedule}
        />
      </Modal>
    </>
  );
};

const Banner = () => {
  const [bannerVisible, setBannerVisible] = useState(
    !localStorage.getItem('bannerDismissed'),
  );

  const handleClose = () => {
    setBannerVisible(false);
    localStorage.setItem('bannerDismissed', 'true');
  };

  return (
    <>
      {bannerVisible && (
        <div className="banner">
          <div className="d-flex gap-2">
            <InfoCircle02 className="mt-1" />
            <span className="text">
              You can also send an email and have it reflect in your drafts. Send to
              <a href="mailto:bills@bujeti.com"> bills@bujeti.com</a>
            </span>
          </div>

          <span className="close-icon" onClick={handleClose}>
            <XcloseIcon width="16" height="16" stroke="#79716B" />
          </span>
        </div>
      )}
    </>
  );
};

export default Bills;
