import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { isSameDay, setHours, setMinutes } from 'date-fns';
import { useEffect, useRef } from 'react';
import { Col, Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = ({
  name = 'datePicker',
  label,
  showIcon = true,
  onFocusDatePicker,
  md = 12,
  wrapperClass = '',
  xs,
  isClock = false,
  hasError = false,
  ...res
}) => {
  const datePicker = useRef(null);

  const focusDatePicker = () => {
    datePicker.current.onInputClick();
  };

  useEffect(() => {
    if (onFocusDatePicker) focusDatePicker();
  }, [onFocusDatePicker]);

  // Calculate the current time
  const currentTime = new Date();
  const currentHours = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();

  // Set the minimum/maximum time to the current time
  const minTime = isSameDay(res?.selected, currentTime)
    ? setHours(setMinutes(new Date(), currentMinutes + 5), currentHours)
    : null;
  const maxTime = isSameDay(res?.selected, currentTime)
    ? setHours(setMinutes(new Date(), 59), 23)
    : null;

  return (
    <Form.Group
      as={Col}
      md={md}
      xs={xs}
      className={classNames('groupWrapper form-group position-relative', {
        [wrapperClass]: wrapperClass,
      })}
    >
      <label id={label} className="textareaLabel form-label">
        {label}
      </label>
      <div
        id={res.id}
        className={classNames(
          'datepicker-div w-100 left-clock date-select',
          { ['has-error']: hasError },
          { ['disabled']: res?.disabled },
          // { ['left-clock']: isClock },
        )}
      >
        {showIcon && isClock && <ClockCircleOutlined onClick={focusDatePicker} />}
        {showIcon && !isClock && <CalendarOutlined onClick={focusDatePicker} />}

        <ReactDatePicker
          timeFormat="HH:mm"
          dateFormat="MMMM d, yyyy"
          className={classNames('datepicker-form-control', {
            ['disabled']: res?.disabled,
          })}
          wrapperClassName="datePicker"
          ref={datePicker}
          id={name}
          timeIntervals={1}
          minTime={minTime}
          maxTime={maxTime}
          name={name}
          autoComplete="off"
          {...res}
        />
      </div>
    </Form.Group>
  );
};

export default CustomDatePicker;
