import React, { useRef } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import './styles.scss';

const CustomPopover = ({
  content,
  showPopover,
  children,
  clickOutside,
  id = 'popover-basic',
  zIndex = '99999',
  placement = 'bottom-end',
  customClass,
  customClassName = '',
}) => {
  const overlayRef = useRef();

  const handleOutsideClick = (event) => {
    if (showPopover && clickOutside && !overlayRef.current.contains(event.target)) {
      clickOutside();
    }
  };
  return (
    <OverlayTrigger
      trigger="click"
      placement={placement}
      show={showPopover}
      onToggle={handleOutsideClick}
      rootClose
      flip={{
        options: {
          padding: 10, // Adjust the padding value as per your requirement
        },
      }}
      offset={{
        options: {
          offset: [0, 10], // Adjust the margin values as per your requirement
        },
      }}
      overlay={
        <div className="overlay-trigger" style={{ zIndex, padding: 0 }}>
          {/* <OutsideClickHandler onOutsideClick={handleOutsideClick}> */}
          <Popover id={id} class={customClass} ref={overlayRef}>
            <Popover.Body style={{ padding: '0.5rem' }}>{content}</Popover.Body>
          </Popover>
          {/* </OutsideClickHandler> */}
        </div>
      }
    >
      <span className={customClassName}>{children}</span>
    </OverlayTrigger>
  );
};

export default CustomPopover;
