import { Tooltip } from 'antd';
import {
  AlertCircle,
  CancelICon,
  LoadingSpinner,
  PDFIcon,
  TrashIcon,
  VerifiedIcon,
} from 'assets/icons';
import { ReactComponent as CloudArrowUp } from 'assets/icons/CloudArrowUp.svg';
import cs from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  retryUploadFile,
  uploadFileCancel,
  uploadFilesRequest,
} from 'redux/actions/ProgressUploadAction';
import { eventEmitter } from 'redux/sagas/ProgressUploadSaga';
import { truncateMiddle } from 'utils/helper';
import Loading from '../Loading';
import { toastError } from '../toast';
import './styles.scss';

const mergeAndRemoveDuplicates = (existingFiles, newFiles) => {
  const fileMap = new Map();

  existingFiles.forEach((file) => fileMap.set(file.id, file));

  newFiles.forEach((file) => fileMap.set(file.id, file));

  return Array.from(fileMap.values());
};

const ServerFileUpload = ({
  label,
  wrapperClass,
  containerClass,
  onChange,
  setUploadingFile,
  uploadingFile,
  supportType = 'Supported file types: jpeg, png, pdf. Max file size: 5mb',
  acceptedFile = {
    'image/jpeg': ['.jpeg', '.png'],
    'application/pdf': ['.pdf'],
  },
  uploadText = 'Choose a file or drag & drop it here.',
  externalUpload,
  checkIsDisabled,
  removeFile,
  setRemoveFile,
  uploadedFile,
  name = 'file',
  multiple = false,
  isRounded,
  scan = false,
}) => {
  const dispatch = useDispatch();

  const serverUpload = useSelector(({ upload }) => upload);

  const [uploaded, setUploaded] = useState(false);
  const [uploads, setUploads] = useState({
    files: [],
    status: {},
    error: {},
    assetData: [],
  });

  const { files: getFiles = [], error = {}, status = {}, assetData = [] } = uploads;

  const files = mergeAndRemoveDuplicates(getFiles, serverUpload.files);
  const uploadStatus = { ...status, ...serverUpload.status };

  useEffect(() => {
    if (serverUpload.completed) {
      setUploads((prevUploads) => {
        const newFiles = serverUpload?.files?.filter(
          (file) => !prevUploads?.files?.some((f) => f.id === file.id),
        );
        const newAssetData = serverUpload?.assetData?.filter(
          (data) => !prevUploads?.assetData?.some((d) => d.id === data.id),
        );

        return {
          files: [...prevUploads.files, ...newFiles],
          status: { ...prevUploads.status, ...serverUpload.status },
          assetData: [...prevUploads.assetData, ...newAssetData],
        };
      });
    }

    if (serverUpload?.files?.length === 1 && !multiple) {
      setUploaded(true);
    }
  }, [serverUpload, serverUpload.completed]);

  useEffect(() => {
    if (onChange) {
      if (multiple) onChange(assetData);
      else onChange(assetData[0]);
    }
  }, [assetData]);

  const onDrop = useCallback((aceptedFile, rejectedFiles, e) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      if (!multiple || aceptedFile.length > 10) toastError('File number exceeded');
      else toastError('File size exceeded');
      return;
    }
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: uploadingFile,
    maxFiles: multiple ? 10 : 1,
    accept: acceptedFile,
    maxSize: 5 * 1024 * 1024,
    onDrop,
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      dispatch(uploadFilesRequest({ acceptedFiles, scan }));
      if (setUploadingFile) setUploadingFile(true);
    }
  }, [acceptedFiles]);
  //

  const handleMultipleRemove = (code) => {
    const { [code]: removedStatus, ...remainingStatus } = uploads?.status;

    const newObj = {
      files: uploads?.files?.filter((file) => file.id !== code),
      assetData: uploads?.assetData?.filter((asset) => asset.id !== code),
      error: {},
      status: remainingStatus,
    };

    setUploads(newObj);

    if (uploaded) {
      setUploaded(false);
    }
  };

  // useEffect(() => {
  //   if (removeFile) handleRemove();
  // }, [removeFile]);

  const uploadedName = uploadedFile ? 'Change file' : undefined;

  const UploadedFileComponents = ({ handleRemove, file }) => {
    const [progress, setProgress] = useState(0);
    const dispatch = useDispatch();
    const status = uploadStatus[file?.id];

    useEffect(() => {
      const handleProgress = ({ file: updatedFile, progress }) => {
        if (updatedFile?.id === file?.id) {
          setProgress(progress);
        }
      };

      eventEmitter.on('progress', handleProgress);

      return () => {
        eventEmitter.off('progress', handleProgress);
      };
    }, [file?.id]);

    const handleCancel = () => {
      dispatch(uploadFileCancel(file));
    };

    const handleRetry = () => {
      dispatch(retryUploadFile(file));
    };
    const totalFileSize = Math.round(file?.size / 1024);

    const fileSizeProgress = totalFileSize * (progress / 100);

    return (
      <div className="py-3 px-3 rounded-4 d-flex justify-content-between uploaded-file-container">
        {isRounded ? (
          <>
            <div className={'add-more'}>
              {(status === 'uploading' || externalUpload) && (
                <Loading color="#D28B28" size={20} />
              )}
            </div>
          </>
        ) : (
          <>
            <div className="d-flex w-100">
              <PDFIcon />
              <div className="ms-3 w-100">
                <p className="mb-1 p-0 m-0 uploaded-file-container-name">
                  {truncateMiddle(file?.name, 60)}
                </p>
                {status === 'uploading' && (
                  <ProgressBar
                    now={progress ?? 0}
                    label={`${progress ?? 0}%`}
                    visuallyHidden
                  />
                )}
                <p className="uploaded-file-container-details gap-1 d-flex align-items-center p-0 m-0">
                  {status === 'uploading' && `${fileSizeProgress} KB of`} {totalFileSize}{' '}
                  KB •{' '}
                  {status === 'uploaded' ? (
                    <>
                      <VerifiedIcon className="align-text-top" />{' '}
                      <span>
                        Uploaded
                        {/* {format(new Date(item.created_at), 'd/MM/yyyy')} */}
                      </span>
                    </>
                  ) : status === 'uploading' ? (
                    <>
                      <LoadingSpinner /> <span>Uploading...</span>
                    </>
                  ) : ['failure', 'cancelled'].includes(status) ? (
                    <>
                      <AlertCircle className="align-text-top" />{' '}
                      {status === 'cancelled' ? (
                        <span>Cancelled</span>
                      ) : (
                        <span>Failed ({error[file?.id]?.message})</span>
                      )}
                    </>
                  ) : status === 'success' ? (
                    <>
                      <VerifiedIcon className="align-text-top" /> <span>Completed</span>
                    </>
                  ) : null}
                  <span className="d-flex align-items-center gap-1"></span>{' '}
                </p>
              </div>
            </div>

            {status === 'failure' && (
              <div onClick={() => handleRetry()}>
                <span className="upload-error nowrap cursor">Try again</span>
              </div>
            )}

            {status === 'uploading' && (
              <div>
                <CancelICon style={{ width: '10px' }} onClick={() => handleCancel()} />
              </div>
            )}

            {['success', 'failed', 'cancelled'].includes(status) && (
              <div>
                <TrashIcon
                  onClick={() => handleRemove()}
                  width="16"
                  height="16"
                  stroke="#79716B"
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className={cs('fileUpload groupWrapper cursor', { [wrapperClass]: wrapperClass })}
    >
      <div>
        <label className="uploadFileLabel">{label}</label>
        {!uploaded && (
          <div>
            {isRounded ? (
              <Tooltip title="Add more files">
                <div
                  onClick={uploadingFile ? checkIsDisabled : null}
                  className="add-more"
                >
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} id={name} name={name} />
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.9999 7.3335V16.6668M7.33325 12.0002H16.6666"
                        stroke="#A9A29D"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </Tooltip>
            ) : (
              <div
                className={cs('upload-container suploadFileBorder w-100', {
                  [containerClass]: containerClass,
                })}
                onClick={uploadingFile ? checkIsDisabled : null}
              >
                <div {...getRootProps({ className: 'dropzone w-100' })}>
                  <input {...getInputProps()} id={name} name={name} />
                  <div className="uploadFileIcon">
                    <CloudArrowUp />
                  </div>
                  <div className="uploadFileTextBorder">
                    <div className="text-sm fw-medium text-black">{uploadText}</div>
                    <div className="text-xs">{supportType}</div>

                    <button className="btn border xs">Browse file</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {!!files?.length &&
        files?.map((file, index) => (
          <div key={index} className="my-2">
            <UploadedFileComponents
              file={file}
              handleRemove={() => handleMultipleRemove(file?.id)}
            />
          </div>
        ))}
    </div>
  );
};
export default ServerFileUpload;
