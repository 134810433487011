import NotificationsModal from 'components/Notifications/NotificationsModal';
import Sidebar from 'components/Sidebar';
import { toastError } from 'components/UI/toast';
import BujetiPayModal from 'components/bujetiPayModal';
import ExpenseModal from 'components/bujetiPayModal/expense';
import DefaultAPI from 'pages/DefaultAPI';
import { useEffect } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import { logout } from 'redux/actions/AuthAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import VerifcationRoute from 'utils/VerifcationRoute';
import {
  encryptAndSaveToLocalStorage,
  getUserLandingPage,
  isLoggedIn,
  retrieveAndDecryptFromLocalStorage,
} from 'utils/utility';

import Routes from './Routes';
import classNames from 'classnames';

const RenderRoutes = () => {
  const {
    user: { data: { user } = {} },
    loginUser: { data: { user: logedIn } = {} },
  } = useSelector(({ auth }) => auth);

  const { toggle, toggleNotification } = useSelector(({ toggle }) => toggle);

  const { toggleExpense } = useSelector(({ toggleExpense }) => toggleExpense);

  const dispatch = useDispatch();

  const { permissions } = allPermissions();

  const handleLogout = async () => {
    try {
      const { pathname, search, hash } = window.location;
      if (pathname !== '/account-statement') {
        encryptAndSaveToLocalStorage('redirectPath', `${pathname}${search}${hash}`);
        encryptAndSaveToLocalStorage('lastLogin', user.email);
      }

      dispatch(logout(true));
    } catch (err) {
      console.error(err);
    }
  };

  const token = retrieveAndDecryptFromLocalStorage('app-session');
  const tokenTime = retrieveAndDecryptFromLocalStorage('exp-app-session');
  const vendorToken = retrieveAndDecryptFromLocalStorage('vendor-app-session');

  const isToken = Boolean(token);

  const authExp = () => {
    return isToken && new Date().getTime() >= +tokenTime;
  };

  const onAction = () => {
    if (authExp()) {
      handleLogout('action');
      // toastError('Session token expired');
    }
  };

  const onIdle = () => {
    if (authExp()) {
      handleLogout('idle');
      toastError("You've been disconnected due to long period of inactivity");
    }
  };

  //Manage session on interaction
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onAction,
    timeout: 2000,
    throttle: 500,
  });

  const renderRoute = (
    routerProps,
    Component,
    props,
    isPrivate = false,
    isTabRoute = false,
    headerHide,
    routePermission,
    redirect,
    name,
  ) => {
    if (Component) {
      const componentProps = {
        ...routerProps,
        ...props,
      };

      const { pathname: path, state } = routerProps?.location || {};

      if (name === 'vendor-complete-profile' && !vendorToken) {
        return window.open('https://www.bujeti.com/', '_self');
      }

      if (isPrivate) {
        if (authExp()) {
          dispatch(logout(true));
        }

        if (
          routePermission?.length &&
          isLoggedIn() &&
          !hasPermission({ permissions, scopes: routePermission })
        )
          return <Redirect to="/404" />;

        if (!isLoggedIn()) {
          const routeName = ['/', '/login'].includes(path)
            ? '/login'
            : `/login?redirect=${path}`;

          return <Redirect to={routeName} />;
        }

        if (redirect) {
          return <Redirect to={redirect} />;
        }

        return (
          <div className="app-layout">
            {!headerHide && (
              <div className="aside">
                <Sidebar />
              </div>
            )}

            <div className="section">
              <div
                className={classNames('main-content', {
                  'p-0': headerHide,
                  'fade-in': !isTabRoute && !state?.disableFade,
                })}
              >
                <VerifcationRoute>
                  <Component {...componentProps} />
                </VerifcationRoute>
                {toggle && <BujetiPayModal toggle={toggle} />}
                {toggleNotification && (
                  <NotificationsModal toggleNotification={toggleNotification} />
                )}
                {toggleExpense && <ExpenseModal toggle={toggleExpense} />}
              </div>
            </div>
          </div>
        );
      }

      return isLoggedIn() && logedIn && permissions ? (
        <Redirect to={getUserLandingPage({ ...logedIn, permissions })} />
      ) : (
        <Component {...componentProps} />
      ); // eslint-disable-line
    }
    return null;
  };

  return Routes.map((route, index) => {
    return (
      <Route
        key={route.name + index}
        exact={route.exact}
        path={route.path}
        render={(routerProps) =>
          renderRoute(
            routerProps,
            route.component,
            route.props,
            route.isPrivate,
            route.isTabRoute,
            route.headerHide,
            route.permissions,
            route.redirect,
            route.name,
          )
        }
      />
    );
  });
};

const Router = () => <Switch>{RenderRoutes()}</Switch>;

const App = () => {
  const history = useHistory();
  const {
    logout: { success },
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (success) {
      history.push('/login');
    }
  }, [success]);

  return (
    <div className="App">
      <DefaultAPI />
      <Router />
    </div>
  );
};

export default withRouter(App);
