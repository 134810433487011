import React from 'react';
import './index.scss';
import EmptyTaskCard from 'components/UI/EmptyTaskCard';
import { useHistory } from 'react-router-dom';

import { ArrowRight } from 'assets/icons';

const OverviewCard = ({
  title,
  children,
  isEmpty,
  placeHolderText = null,
  minHeight = 350,
  customClass = '',
  routeTo = '',
}) => {
  const history = useHistory();
  return (
    <div className="pt-3 overview-card-view" style={{ minHeight }}>
      {title && (
        <div className=" px-4 card-title justify-content-between w-100 border-bottom">
          {title}
        </div>
      )}
      <div
        className={`card-list px-4 h-100 overflow-x-scroll pt-4 ${
          customClass ? customClass : ''
        }`}
      >
        {isEmpty ? <EmptyTaskCard text={placeHolderText} /> : children}
      </div>

      {routeTo && (
        <div className="d-flex px-4 justify-content-end">
          <button className="view-all" onClick={() => history.push(routeTo)}>
            {' '}
            View all
            <ArrowRight color="#D28B28" />
          </button>
        </div>
      )}
    </div>
  );
};

export default OverviewCard;
