import cs from 'classnames';
import { Col, Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { theStyle } from '../CustomSelectRadio';
import { ReactComponent as NigeriaCrest } from '../../../assets/icons/nigeria-crest.svg';
import { ReactComponent as UsaCrest } from '../../../assets/icons/united-states-crest.svg';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const Menu = (props) => {
  const {
    selectProps: { addAction, extendComponent },
    children,
  } = props;

  return (
    <components.Menu {...props}>
      <div>
        <div>{children}</div>
        {extendComponent && (
          <button className="add-beneficiary" onClick={addAction}>
            {extendComponent}
          </button>
        )}
      </div>
    </components.Menu>
  );
};

const Option = (props) => {
  const { children } = props;
  return <components.Option {...props}>{children}</components.Option>;
};

const formatGroupLabel = (data) => (
  <div className="py-1">
    <span>{data.label}</span>
  </div>
);

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <>
      <span className="pr-5">
        {props.data.value === 'NGN' || props.data.value === 'NGA' ? (
          <NigeriaCrest />
        ) : (
          <UsaCrest />
        )}
      </span>
      <span className="ml-2 pt-2" style={{ position: 'relative', top: 1.5 }}>
        {' '}
        {props.data.label}
      </span>
    </>
  </components.SingleValue>
);

const CustomSelect = ({
  label = '',
  closeMenuOnSelect = true,
  md = 12,
  wrapperClass = '',
  defaultValue,
  createable = false,
  formatOptionLabel,
  classNamePrefix = 'dropdown-select',
  isCurrency,
  customStyles = null,
  onclickExtended = null,
  components: otherComponents,
  hasError = false,
  extendComponent,
  addAction,
  ...res
}) => {
  const formatGroupLabel = (data) => (
    <div className="py-1">
      <span>{data.label}</span>
    </div>
  );

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <>
        <span className="pr-5">
          {props.data.value === 'NGN' || props.data.value === 'NGA' ? (
            <NigeriaCrest />
          ) : (
            <UsaCrest />
          )}
        </span>
        <span className="ml-2 pt-2" style={{ position: 'relative', top: 1.5 }}>
          {' '}
          {props.data.label}
        </span>
      </>
    </components.SingleValue>
  );

  const Menu = (props, label) => {
    const {
      selectProps: { onclickExtended, extendComponent },
      children,
    } = props;

    return (
      <components.Menu {...props}>
        <div>
          <div>{children}</div>
          {extendComponent && (
            <button className="add-beneficiary" type="button" onClick={onclickExtended}>
              {extendComponent}
            </button>
          )}
        </div>
      </components.Menu>
    );
  };

  return (
    <Form.Group
      as={Col}
      md={md}
      className={cs('groupWrapper form-group custom', { [wrapperClass]: wrapperClass })}
    >
      {label && (
        <Form.Label className={`${res.labelClassNmaes} form-label`}>{label}</Form.Label>
      )}

      {createable ? (
        <CreatableSelect
          isClearable
          defaultValue={defaultValue}
          closeMenuOnSelect={closeMenuOnSelect}
          maxMenuHeight={210}
          styles={theStyle(hasError, customStyles)}
          classNamePrefix={classNamePrefix}
          // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          components={
            onclickExtended
              ? {
                  ...otherComponents,
                  Menu: (props) => Menu(props),
                  Option,
                  animatedComponents,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }
              : {
                  ...otherComponents,
                  animatedComponents,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }
          }
          onclickExtended={onclickExtended}
          {...res}
        />
      ) : (
        <Select
          defaultValue={defaultValue}
          closeMenuOnSelect={closeMenuOnSelect}
          styles={theStyle(hasError, customStyles)}
          maxMenuHeight={210}
          extendComponent={extendComponent}
          formatOptionLabel={formatOptionLabel}
          formatGroupLabel={formatGroupLabel}
          classNamePrefix={classNamePrefix}
          menuPlacement="auto"
          components={
            onclickExtended
              ? {
                  ...otherComponents,
                  Menu: (props) => Menu(props),
                  ...(isCurrency ? { SingleValue } : { Option }),
                  animatedComponents,
                  ...(isCurrency && { SingleValue }),
                }
              : {
                  ...otherComponents,
                  animatedComponents,
                  ...(isCurrency ? { SingleValue } : { Option }),
                }
          }
          onclickExtended={onclickExtended}
          // components={}
          {...res}
        />
      )}
    </Form.Group>
  );
};
export default CustomSelect;
