import { Skeleton } from 'antd';

const SearchLoader = () => {
  return (
    <div className="d-flex pt-3 pb-2">
      <Skeleton.Input
        active
        style={{
          borderRadius: 8,
          height: 40,
          width: '260px',
          minWidth: '260px',
        }}
      />

      <div className="ms-auto">
        <Skeleton.Input
          active
          style={{
            borderRadius: 8,
            height: 40,
            width: '100px',
            minWidth: '100px',
          }}
        />
      </div>
    </div>
  );
};

export default SearchLoader;
