import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Viewer, Worker } from '@react-pdf-viewer/core';

import cs from 'classnames';

import BillForm from './AddBill';
import { Modal } from 'react-bootstrap';
import DeleteDialog from 'components/DeleteDialog';
import CustomButton from 'pages/Profile/Components/CustomButton';
import { toastSuccess } from 'components/UI/toast';
import AssetViewer from 'components/AssetViewer';
import Loading, { FullPageLoader } from 'components/UI/Loading';
import ClientFileUpload from 'components/UI/FileUpload/ClientFileUpload';
import ServerFileUpload from 'components/UI/FileUpload/ServerFileUpload';

import { createAssets } from 'redux/actions/AssetsAction';
import { resetUploadState } from 'redux/actions/ProgressUploadAction';

import {
  CopyIcon02,
  ArrowLeft02,
  FilePlus,
  InfoCircle02,
  File03,
} from 'assets/icons/index';

const UploadStatus = Object.freeze({
  UPLOADING: 'uploading',
  SUCCESS: 'success',
  FAILURE: 'failure',
});

const EmptyState = () => {
  return (
    <section className="rhs-empty__state">
      <div className="pattern-holder">
        <div className="content">
          <div className="inner-holder">
            <span className="icon-holder">
              <FilePlus stroke="red" />
            </span>

            <div className="inner-text">
              <p> Your details will appear here</p>
              <p>Upload your invoice and validate your entries </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const LoadingState = () => {
  return (
    <section className="rhs-empty__state">
      <div className=" bill-loader">
        <Loading size={32} color="#D28B28" />
        <p className="loader-text mt-4"> Loading...</p>
        <p className="loader-text message">
          We’re scanning your bill to complete and display your invoice details
        </p>
      </div>
    </section>
  );
};

const CreateBill = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [isEmpty, setIsEmpty] = useState(false);
  const [payload, setPayload] = useState({
    currency: 'NGN',
    terms: 0,
    reference: '',
    balanceDue: 0,
    dueDate: '',
    products: [
      {
        name: '',
        quantity: 1,
        unitPrice: '',
      },
    ],
  });

  const {
    assetData = [],
    error = {},
    status = {},
    files = [],
  } = useSelector(({ upload }) => upload);

  const activeFile = files[0]?.id || '';
  const fileStatus = status[activeFile];
  let errorUpload = error[activeFile];

  const { url = '', fileName = '', fileType = '', scannedData = {} } = assetData[0] || {};

  useEffect(() => {
    const hasData = scannedData && Object.keys(scannedData).length > 0;

    if (fileStatus === UploadStatus.SUCCESS) {
      if (hasData) {
        setStep(2);
        aliaseScannedData();
        toastSuccess('Bill scanned successfully');
      } else {
        setIsEmpty(true);
      }
    }
  }, [fileStatus]);

  const aliaseScannedData = () => {
    const mappedPayload = {
      ...payload,
      currency: scannedData?.Currency || 'NGN',
      reference: scannedData['Purchase Order Number'] || '',
      dueDate: scannedData['Due Date'] || '',
      products: scannedData['Line Items'].map((item) => ({
        name: item?.description || '',
        quantity: item?.quantity || 1,
        unitPrice: item?.price || 0,
      })),
    };

    setPayload(mappedPayload);
  };

  const sortProductValue = (index, event) => {
    let { name, value } = event.target;

    if (['quantity', 'unitPrice'].includes(name)) {
      value = value.replace(/,/g, '').replace(/\D/g, '');
    }

    const products = payload.products;
    products[index] = {
      ...products[index],
      [name]: value,
    };

    setPayload({ ...payload, products });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText('bills@bujeti.com');
    toastSuccess('Copied to clipboard successfully!');
  };

  const uploadText = (
    <span style={{ color: '#1C1917' }}>
      <span style={{ color: '#D28B28' }}>Choose a file</span> or drag & drop it here
    </span>
  );

  const updatePayload = (data) => {
    setPayload({
      ...payload,
      [data.target]: data.value,
    });
  };

  const addProduct = () => {
    setPayload({
      ...payload,
      products: [
        ...payload.products,
        {
          name: '',
          quantity: 1,
          unitPrice: '',
        },
      ],
    });
  };

  const removeItem = (index) => {
    setPayload({
      ...payload,
      products: payload.products.filter((_, i) => i !== index),
    });
  };

  const handleFile = (file) => {
    if (!file?.length) {
      dispatch(resetUploadState());
    }
  };

  const handleManualInput = () => {
    errorUpload = '';
    setIsEmpty(false);
    setStep(2);
  };

  return (
    <section className="create-holder">
      <div className={`lhs ${url ? 'rounded-0' : ''}`}>
        <div
          className={cs('drag-region', {
            ['has-file']: fileStatus,
            ['no-bg']: url,
          })}
        >
          {url ? (
            <>
              {fileType.includes('pdf') ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer className="check" fileUrl={url} />
                </Worker>
              ) : (
                <div className="ocr-image">
                  <img src={url} alt={fileName} />
                </div>
              )}
            </>
          ) : (
            <ServerFileUpload
              scan
              containerClass="bill-upload__container"
              uploadText={uploadText}
              supportType="JPEG, PNG, and PDF formats, up to 10 MB."
              onChange={(file) => handleFile(file)}
            />
          )}
        </div>

        {/* Email region */}
        {!url && (
          <div className="email-holder">
            <InfoCircle02 />
            <div>
              <p>You can also send an email and have it reflect in your drafts</p>
              <p className="d-flex align-items-center gap-1">
                You can send an email to{' '}
                <a href="mailto:bills@bujeti.com"> bills@bujeti.com</a>
                <span onClick={copyToClipboard}>
                  <CopyIcon02 />
                </span>
              </p>
            </div>
          </div>
        )}
      </div>

      {/* RHS */}
      <div className="rhs">
        <span className="back-action" onClick={() => history.push('/expenses/bills')}>
          <ArrowLeft02 />
          Back
        </span>

        {step === 1 && (
          <div>
            {UploadStatus.UPLOADING === fileStatus ? (
              <LoadingState />
            ) : (
              <EmptyState status={status} />
            )}
          </div>
        )}
        {step === 2 && (
          <BillForm
            payload={payload}
            addProduct={addProduct}
            removeItem={removeItem}
            updatePayload={updatePayload}
            sortProductValue={sortProductValue}
          />
        )}
      </div>

      <Modal show={isEmpty || errorUpload} centered dialogClassName="custom-dialog">
        <DeleteDialog
          icon={<File03 />}
          hasFooterBtns={false}
          hasCancelIcon={false}
          bodyCustomClass="d-flex flex-column align-items-center justify-content w-100"
          title={
            <span
              className="text-center fw-medium"
              style={{ color: '#1C1917', fontSize: '1rem' }}
            >
              Failed to scan bill{' '}
            </span>
          }
          subTitle={
            <p
              className="text-center mx-auto pt-1"
              style={{ color: '#1C1917', fontSize: '0.875rem', width: '85%' }}
            >
              {' '}
              It seems we are unable to scan your invoice. Not to worry, you can also
              input details manually{' '}
            </p>
          }
          footerItem={
            <CustomButton
              onClick={handleManualInput}
              className="bill-manual-input dark-button w-100"
            >
              Manually input your bill details
            </CustomButton>
          }
          deleteText="Archive"
        />
      </Modal>
    </section>
  );
};

export default CreateBill;
