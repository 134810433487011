import { numFormatter } from 'utils/utility';
import { getCurrency } from 'utils/helper';
import CurrencyFormat from 'react-currency-format';

import { Skeleton } from 'antd';

import { Col, Row } from 'react-bootstrap';

const FormattedCurrency = ({ value, prefix }) => {
  const formattedValue = numFormatter(value / 100);

  return (
    <CurrencyFormat
      prefix={prefix}
      value={formattedValue}
      displayType="text"
      thousandSeparator={true}
      isNumericString
      renderText={(formattedValue) => {
        const [integerPart, decimalPart] = formattedValue.split('.');
        return (
          <span>
            {integerPart}
            {decimalPart && <span style={{ color: '#A9A29D' }}>.{decimalPart}</span>}
          </span>
        );
      }}
    />
  );
};

const SkeletalLoader = () => {
  const statuses = ['Paid', 'Overdue', 'Approved', 'Pending', 'Draft'];
  return (
    <Row className="mt-1">
      {statuses.map((status) => (
        <Col md={2} xs={6} className="mb-3 w-20" key={status}>
          <div className="card bill">
            <p className="caption bill">{status}</p>
            <Skeleton.Input
              active
              className="mt-1"
              style={{
                borderRadius: 4,
                height: 20,
                width: '120px',
                minWidth: '120px',
              }}
            />
          </div>
        </Col>
      ))}
    </Row>
  );
};

const BillAnalytics = ({ data, loading, hasQuery = false }) => {
  const totalPaid = data?.summary?.totalPaid?.['NGN'] || 0;
  const totalOverdue = data?.summary?.totalOverdue?.['NGN'] || 0;
  const totalApproved = data?.summary?.totalApproved?.['NGN'] || 0;
  const totalPending = data?.summary?.totalPending?.['NGN'] || 0;
  const totalDraft = data?.summary?.totalDraft?.['NGN'] || 0;

  return (
    <section className="procurement-analytics-region pt-2">
      <Row>
        {loading ? (
          <SkeletalLoader />
        ) : !!data?.bills?.length || hasQuery ? (
          <>
            <Col md={2} xs={6} className="mb-3 w-20">
              <div className="card bill">
                <p className="caption bill">Paid</p>
                <p className="value bill">
                  <FormattedCurrency value={totalPaid} prefix={getCurrency('NGN')} />
                </p>
              </div>
            </Col>

            <Col md={2} xs={6} className="mb-3 w-20">
              <div className="card bill">
                <p className="caption bill">Overdue</p>
                <p className="value bill">
                  <FormattedCurrency value={totalOverdue} prefix={getCurrency('NGN')} />
                </p>
              </div>
            </Col>

            <Col md={2} xs={6} className="mb-3 w-20">
              <div className="card bill">
                <p className="caption bill">Approved</p>
                <p className="value bill">
                  <FormattedCurrency value={totalApproved} prefix={getCurrency('NGN')} />
                </p>
              </div>
            </Col>

            <Col md={2} xs={6} className="mb-3 w-20">
              <div className="card bill">
                <p className="caption bill">Pending</p>
                <p className="value bill">
                  <FormattedCurrency value={totalPending} prefix={getCurrency('NGN')} />
                </p>
              </div>
            </Col>

            <Col md={2} xs={6} className="mb-3 w-20">
              <div className="card bill">
                <p className="caption bill">Draft</p>
                <p className="value bill">
                  <FormattedCurrency value={totalDraft} prefix={getCurrency('NGN')} />
                </p>
              </div>
            </Col>
          </>
        ) : null}
      </Row>
    </section>
  );
};

export default BillAnalytics;
