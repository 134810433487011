import 'jspdf-autotable';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BulkTransactionColumns,
  columns,
  columnsTransactionCSV,
  columnsTransactionQuickBooks,
  columnsTransactionXero,
} from 'utils/mockData';

import { Col, Container, Modal, Row } from 'react-bootstrap';

import { useHistory, useLocation } from 'react-router-dom';

import { Skeleton } from 'antd';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';

import { getBudgets } from 'redux/actions/BudgetsAction';
import {
  exportBatchTransaction,
  exportTransaction,
  getSingleTransaction,
} from 'redux/actions/TransactionsAction';

import { TransactionLogo } from 'assets/images';
import { CurrencyType, TransactionStatusType } from 'components/FilterModal/FilterHelper';
import TransactionDrawer from 'components/TransactionModal/TransactionDrawer';
import Loading from 'components/UI/Loading';
import { jsPDF, PDFDownloadLink } from 'components/UIHooks/JsPDF';
import { getBatchPayment, payNowAction } from 'redux/actions/PaymentAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import {
  buildBulkTransactionTableData,
  buildExportTransactionData,
  buildExportTransactionQuickBooks,
  buildExportTransactionXero,
  capitalizeFirstLetter,
} from '../../utils/helper';
import TransactionEmptyStateData from './transactionEmptystateData';
import { BankNoteO1Icon, DownloadIcon } from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import { useDebounce } from 'hooks/useDebounce';
import ReceiptPdf from 'components/ReceiptPdf';
import { toastSuccess } from 'components/UI/toast';

const BulkTransactionTable = ({ handleSelect }) => {
  const { permissions } = allPermissions();

  const [selectTransaction, setSelectTransaction] = useState(null);

  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [filterData, setFilterData] = useState([
    { title: 'Amount', type: 'slider' },
    ...TransactionStatusType,
    ...CurrencyType,
  ]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [type, setType] = useState(null);
  const [exportData, setExportData] = useState([]);
  const [isFillData, setIsFillData] = useState(false);
  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;
  const [filteredQuery, setFilteredQuery] = useState({});
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const [loadActions, setLoadActions] = useState(false);

  const [assets, setAssets] = useState({
    active: {},
    assetsList: [],
    visible: false,
  });

  const canViewBudget = hasPermission({
    permissions,
    scopes: ['budget-*', 'budget-view'],
  });

  const {
    exportBatchTransaction: { data: exportBatchData = [], loading: exportLoading },
    getSingleTransaction: { data: singleTransaction = {} },
  } = useSelector(({ transaction }) => transaction);
  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);
  const {
    getBatchPayment: { data: { meta = {} } = {}, data: transactions = [], loading },
  } = useSelector(({ payments }) => payments);

  const { page = 1, total = 50, hasMore = false, perPage = 50, nextPage } = meta;
  const {
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    payNowAction: { loading: loadingPayNow, success: successPayNow },
  } = useSelector(({ payments }) => payments);

  const toggleHandler = () => {
    if (budgetData?.budgets?.length && !transactions.length)
      return history.push('/transactions/batch-payment');
    return history.push('/expenses/budgets');
  };

  const generatePdfSuccess = async (event, instance) => {
    event?.stopPropagation();
    if (instance.loading || !instance.url) return;
    toastSuccess('Receipt downloaded');
  };

  useEffect(() => {
    let data = [];

    exportBatchData?.forEach((item) => {
      data = [...data, ...item?.transactions];
    });

    setExportData(data);
  }, [exportBatchData?.length]);

  const handleRowClick = (row) => {
    if (row.batchCode) {
      history.push(`/transactions/batch/${row.batchCode}`);
    } else {
      setSelectTransaction(row);
      setAssets({
        ...assets,
        assetsList: row?.transactionData?.receipts,
      });
    }
  };

  const check = transactions?.some((item) =>
    ['approved', 'success'].includes(item.status),
  );
  const tableColumn = useMemo(
    () =>
      BulkTransactionColumns.filter((item) => {
        if (!check) return item.Header !== 'Action';
        return item.Header;
      }),
    [check],
  );

  useEffect(() => {
    if (!!budgetData?.budgets?.length && transactions.length > 0 && !isFillData) {
      setIsFillData(true);
      const data = [];
      budgetData?.budgets.forEach((budget) => {
        const { code, name } = budget;
        data.push({
          value: code,
          label: name,
          isSelected: false,
        });
      });

      const allTransactions = transactions.map((transaction) => {
        return {
          value: transaction.payer ? transaction.payer.code : null,
          label: transaction.payer
            ? `${transaction.payer.firstName} ${transaction.payer.lastName}`
            : 'N/A',
          isSelected: false,
        };
      });
      const uniqueArray = allTransactions.filter(
        (initialValue, index, array) =>
          array.findIndex((v2) => v2.value === initialValue.value) === index,
      );

      const newItem = [
        { title: 'Budget', list: data },
        { title: 'Payer', list: uniqueArray },
      ];

      // Check if any item in newItem already exists in filterData by title
      if (
        !newItem.some((newItemElement) =>
          filterData.some((item) => item.title === newItemElement.title),
        )
      ) {
        setFilterData([...filterData, ...newItem]);
      }
    }
    if (transactions.length) setTableData(buildBulkTransactionTableData(transactions));
  }, [budgetData?.budgets, transactions]);

  useEffect(() => {
    if (!transactions.length) dispatch(getBatchPayment());
    if (!budgetData?.budgets && canViewBudget) dispatch(getBudgets());

    return () => {
      if (filtered) dispatch(getBatchPayment());
    };
  }, [filtered]);

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(
        getBatchPayment({
          ...filteredQuery,
          search: debouncedValue,
        }),
      );
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(getBatchPayment({ ...filteredQuery }));
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleFilter = (query) => {
    const amount = filterData.filter(({ title }) => title === 'Amount');
    const [min, max] = amount[0]?.value || [];
    const min_amount = min ? min * 100 : undefined;
    const max_amount = max ? max * 100 : undefined;
    setFilteredQuery({ ...query, min_amount, max_amount });
    dispatch(getBatchPayment({ ...query, min_amount, max_amount }));
    isFiltered.current = !!Object.keys(query).length;
  };

  const handleExport = () => {
    dispatch(exportBatchTransaction(filteredQuery));
  };

  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const columnStyles = {};
    const title = 'Transactions Report';
    const headers = [
      columnsTransactionCSV
        .filter((item) => item.Header !== 'Files')
        .map((item) => item.Header),
    ];

    columns.forEach((column, index) => {
      columnStyles[index] = { cellWidth: 60 }; // Adjust the width as needed
    });

    const body = buildExportTransactionData(exportData);

    let content = {
      startY: 80,
      head: headers,
      body,
      columnStyles,
    };

    doc.addImage(TransactionLogo, 'JPEG', marginLeft, 25, 90, 50.5);
    // doc.text(title, 80, 40);
    doc.autoTable(content);
    doc.save('Batch Transaction Statement.pdf');
  };
  const csvData = [
    columnsTransactionCSV
      .filter((item) => item.Header !== 'Files')
      .map((item) => item.Header),
    ...buildExportTransactionData(exportData),
  ];

  const csvDataXero = [
    columnsTransactionXero.map((item) => item.Header),
    ...buildExportTransactionXero(exportData),
  ];

  const csvDataQuickBooks = [
    columnsTransactionQuickBooks.map((item) => item.Header),
    ...buildExportTransactionQuickBooks(exportData),
  ];

  const clearFilters = () => {
    setFilteredQuery({});
    setFilterData([
      { title: 'Amount', type: 'slider' },
      ...TransactionStatusType,
      ...CurrencyType,
    ]);
  };

  const handlePreviousPage = (page) => {
    dispatch(getBatchPayment({ perPage, page, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(getBatchPayment({ perPage, page, ...filteredQuery }));
  };

  const clearLocationState = () => {
    history.replace({ state: {} });
    dispatch(getBatchPayment());
  };

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    setSelectedOption(value);
    setType(type.toLowerCase());

    return setConfirmModal(true);
  };

  const closeModal = () => {
    setIsPopoverOpen(false);
    setConfirmModal(false);
  };

  useEffect(() => {
    if (successPayNow) {
      closeModal();
      isFiltered.current = true;
      dispatch(getBatchPayment({ ...filteredQuery }));
    }
  }, [successPayNow]);

  const handleConfirm = () => {
    const code = selectedOption?.code;
    if (type === 'pay') dispatch(payNowAction({ code }));
  };

  const activeTxCode = useRef(null);

  useEffect(() => {
    if (singleTransaction?.data) setLoadActions(true);
  }, [singleTransaction?.data]);

  const fetchSingleData = useCallback(
    (data) => {
      const { code = '' } = data?.transactionData || {};

      activeTxCode.current = code;
      if (activeTxCode.current) dispatch(getSingleTransaction(code));
    },
    [activeTxCode.current],
  );

  const Actions = useCallback(
    ({ list: selectedData }) => {
      const status = selectedData?.status?.value.toLowerCase();

      // useMemo(() => {
      //   if (selectedData?.transactionData?.code !== activeTxCode.current) {
      //     setLoadActions(false);
      //     if (['success'].includes(status)) fetchSingleData(selectedData);
      //   }
      // }, []);

      return (
        <div className="actions-dialog">
          {!['approved'].includes(status) && (
            <div
              className="actionLink"
              onClick={(event) => {
                setIsPopoverOpen(false);
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              No options available
            </div>
          )}
          {['approved'].includes(status) && (
            <div
              className="actionLink"
              onClick={(event) =>
                actionHandler(event, 'pay', selectedData?.transactionData)
              }
            >
              <BankNoteO1Icon style={{ marginLeft: '-4px', marginRight: '6px' }} /> Make
              payment
            </div>
          )}
          {/* {['success'].includes(status) && (
            <PDFDownloadLink
              document={
                <ReceiptPdf
                  data={{
                    ...selectedData?.transactionData,
                    senderAccount: singleTransaction?.data?.senderAccount,
                  }}
                  companyData={companyData}
                />
              }
              fileName={`Transaction receipt for ${selectedData?.description}.pdf`}
              style={{
                textDecoration: 'none',
                height: 40,
                display: 'flex',
                width: '100%',
                fontFamily: 'Inter var !important',
                color: '#212529',
                alignItems: 'center',
              }}
              className="actionLink"
              onClick={(event, instance) => generatePdfSuccess(event, instance)}
            >
              <DownloadIcon className="mr-4" width={16} height={16} />
              Download receipt
            </PDFDownloadLink>
          )} */}
        </div>
      );
    },
    [singleTransaction?.data?.code, loadActions],
  );

  const show = !!tableData?.length || (filtered && !tableData?.length);
  const loadingState = loading && !filtered;

  if (loadingState)
    return (
      <section className="pt-3 fade-in">
        <div className="d-flex">
          <Skeleton.Button
            active
            shape="square"
            style={{ borderRadius: 8, height: '40px', width: '16rem' }}
          />

          <div className="ms-auto d-flex gap-2">
            {Array.from({ length: 2 }).map((_, i) => (
              <Skeleton.Button
                key={i}
                active
                shape="square"
                style={{ borderRadius: 8, height: '40px', width: '7rem' }}
              />
            ))}
          </div>
        </div>

        <div className="mt-4">
          <Table columns={tableColumn} data={tableData} hasCheckBox={false} loading />
        </div>
      </section>
    );

  return (
    <div>
      <TopBar
        showFilter={show}
        setSearchVal={setSearch}
        searchVal={search}
        withOutSearch
        showBarSearch={show}
        addOnClick={toggleHandler}
        inputPlaceholder="Search"
        filterData={filterData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        // addExport
        exportLoading={exportLoading}
        handleFilterApply={handleFilter}
        withDate
        dateTitle="Transaction Date"
        handleExport={handleExport}
        csvData={csvData}
        exportPDF={exportPDF}
        clearFilters={clearFilters}
        addExport={show}
        csvFileName="Batch Transaction Statement.csv"
        csvPlatform={{ quickbooks: csvDataQuickBooks, xero: csvDataXero }}
      />
      {!tableData.length ? (
        <div>
          {isFiltered.current ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText={`You have no transactions for this ${
                  location?.search ? 'card' : 'filter'
                }`}
                bodyText="Alter filter to see transactions"
                withButton={true}
                buttonLabel="View all transactions"
                onClickHandler={clearLocationState}
              />
            </div>
          ) : (
            <TransactionEmptyStateData handleSelect={handleSelect} />
          )}
        </div>
      ) : (
        <>
          <Container className="px-0">
            <Row className="py-4 ">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={tableColumn}
                  data={tableData}
                  pagination
                  onRowClick={handleRowClick}
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  totalPage={Math.ceil(total / perPage)}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  loading={loadingState}
                />
              </Col>
            </Row>
          </Container>

          <TransactionDrawer
            selectTransaction={selectTransaction}
            setSelectTransaction={setSelectTransaction}
          />
        </>
      )}

      <Modal show={confirmModal} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`${capitalizeFirstLetter(type)} transaction`}
          subTitle={`Are you sure you want to ${type} this transaction?`}
          onConfirm={handleConfirm}
          onCancel={closeModal}
          actionBtnText="Confirm"
          loading={loadingPayNow}
        />
      </Modal>
    </div>
  );
};

export default BulkTransactionTable;
