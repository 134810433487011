import { Skeleton } from 'antd';
import Table from 'components/Table';
import useSegmentVisibility from 'hooks/useSegmentVisibility';

const Loader = ({ columns, data, isRequest = false }) => {
  const visibleSegment = useSegmentVisibility(2);

  return (
    <section>
      {visibleSegment >= 1 && (
        <div className={`d-flex fade-in ${!isRequest ? 'mt-4' : ''}`}>
          <Skeleton.Input
            active
            size={40}
            style={{ borderRadius: 6, minWidth: '16rem' }}
          />

          <div className="ms-auto d-flex gap-2 align-items-center">
            <Skeleton.Button
              active
              shape="square"
              style={{ borderRadius: 6, height: '40px', width: '5rem' }}
            />

            <Skeleton.Button
              active
              shape="square"
              style={{ borderRadius: 6, height: '40px', width: '6.5rem' }}
            />
          </div>
        </div>
      )}

      {visibleSegment >= 2 && (
        <div className="mt-4 fade-in">
          <Table columns={columns} data={data} hasCheckBox={false} loading />
        </div>
      )}
    </section>
  );
};

export default Loader;
