import {
  FILES_PLOAD_CANCELLED,
  FILES_UPLOAD_CANCEL,
  FILES_UPLOAD_COMPLETED,
  FILES_UPLOAD_FAILURE,
  FILES_UPLOAD_PROGRESS,
  FILES_UPLOAD_REQUEST,
  FILES_UPLOAD_SUCCESS,
  REMOVE_FILES,
  RESET_FILES_UPLOAD_STATE,
  RETRY_FILES_UPLOAD,
} from 'redux/reducers/ProgressUploadReducer';
import { v4 as uuidv4 } from 'uuid';

export const uploadFilesRequest = ({ acceptedFiles, scan = false }) => {
  const files = acceptedFiles.map((file) => {
    const newFile = new File([file], file.name, { type: file.type });
    newFile.id = uuidv4();

    return newFile;
  });
  return {
    type: FILES_UPLOAD_REQUEST,
    payload: { files, scan },
  };
};

export const uploadFileProgress = (file, progress) => ({
  type: FILES_UPLOAD_PROGRESS,
  payload: { file, progress },
});

export const uploadFileSuccess = (data) => ({
  type: FILES_UPLOAD_SUCCESS,
  payload: data,
});

export const uploadFileFailure = (file, error) => ({
  type: FILES_UPLOAD_FAILURE,
  payload: { file, error },
});

export const uploadFileCancel = (file) => ({
  type: FILES_UPLOAD_CANCEL,
  payload: { file },
});

export const uploadFileCancelled = (file) => ({
  type: FILES_PLOAD_CANCELLED,
  payload: { file },
});

export const retryUploadFile = (file) => ({
  type: RETRY_FILES_UPLOAD,
  payload: { file },
});

export const deleteFile = (id) => ({
  type: REMOVE_FILES,
  payload: { id },
});

export const fileUploadComplete = () => ({
  type: FILES_UPLOAD_COMPLETED,
});

export const resetUploadState = () => ({
  type: RESET_FILES_UPLOAD_STATE,
});
