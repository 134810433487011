import { Skeleton, Tooltip } from 'antd';
import CustomButton from 'components/UI/CustomButton';
import CurrencyFormat from 'react-currency-format';

import { ArrowUpIcon, TransactionsIcon, InfoCircle } from 'assets/icons';

import { getCurrency } from 'utils/helper';
import { numFormatter } from 'utils/utility';

const FormattedCurrency = ({ value, prefix }) => {
  const formattedValue = numFormatter(value / 100);

  return (
    <CurrencyFormat
      prefix={prefix}
      value={formattedValue}
      displayType="text"
      thousandSeparator={true}
      isNumericString
      renderText={(formattedValue) => {
        const [integerPart, decimalPart] = formattedValue.split('.');
        return (
          <span>
            {integerPart}
            {decimalPart && <span style={{ color: '#BFBFBD' }}>.{decimalPart}</span>}
          </span>
        );
      }}
    />
  );
};

const Balances = () => {
  return (
    <section>
      <div className="d-flex w-100">
        <div className="overview-balances__holder">
          <Tooltip
            title={
              <span style={{ fontSize: 12 }}>
                The sum of your accounts, budgets and cards balances
              </span>
            }
          >
            <p className="text">
              Total balance
              <span className="icon-animate">
                <InfoCircle
                  style={{ fontSize: '14px', paddingTop: '2px' }}
                  fill="#BFBFBD"
                />
              </span>
            </p>
          </Tooltip>

          <p className="value">
            <FormattedCurrency value={20000087} prefix={getCurrency('NGN')} />
          </p>
        </div>

        <div className="ms-auto d-flex gap-3">
          <div className="add-custom add-button overview d-flex white align-items-center cursor">
            <TransactionsIcon stroke="#7F7F7D" />
            <span>Internal transfer</span>
          </div>

          <div className="add-custom add-button overview d-flex white align-items-center cursor">
            <ArrowUpIcon stroke="#7F7F7D" style={{ transform: 'rotate(180deg)' }} />
            <span>Deposit funds</span>
          </div>

          <CustomButton className="add-button overview">
            <ArrowUpIcon stroke="#F5F5F4" />
            Send funds
          </CustomButton>
        </div>
      </div>

      {/* Cards Here */}
      <div className="mt-3 d-flex gap-3">
        <div className="overview-display__cards">
          <p className="caption">Accounts</p>

          <p className="value">
            <FormattedCurrency value={20000087} prefix={getCurrency('NGN')} />
          </p>
        </div>

        <div className="overview-display__cards">
          <p className="caption">Budgets</p>
          <p className="value">
            <FormattedCurrency value={20000087} prefix={getCurrency('NGN')} />
          </p>
        </div>

        <div className="overview-display__cards">
          <p className="caption d-flex justify-content-between align-items-center ">
            NGN cards
            <span>..</span>
          </p>

          <p className="value">
            <FormattedCurrency value={20000087} prefix={getCurrency('NGN')} />
          </p>
        </div>
      </div>
    </section>
  );
};

export default Balances;
