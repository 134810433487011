import React from 'react';
import './index.scss';
import { Skeleton } from 'antd';

const MetricCard = ({ label, value, loading }) => {
  return (
    <div className="metrics__wrapper">
      <div className="metrics__wrapper-flex">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center text-center label">
            <p className="mb-0 pb-2">{label}</p>
          </div>
          <div className="d-flex text-center">
            <div className="value">
              {loading ? (
                <Skeleton.Input
                  active
                  size={24}
                  style={{ borderRadius: 4, minWidth: '3.75rem' }}
                />
              ) : (
                <h2>{value}</h2>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetricCard;
